import { Field } from "formik"
import _capitalize from "lodash/capitalize"
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { useTranslation } from "react-i18next"
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { useCallback, useEffect, useState } from "react"
import { useCache } from "../../context/cache.context"
import AddressFormField from "./AddressFormField"
import SimpleLoading from "../Display/SimpleLoading"
import { t } from 'i18next';
import { createLanguagesMapping, languagesCode2 } from '../helpers/fieldHelper';

const BASE_CONTACT_VALUE = {
    legal_person_type: "",
    contact_method: "",
    name: "",
    surname: "",
    notify_closure: "",
    restrict_calls: "",
    position: "",
    department: "",
    language: "",
    contact_details: {
        address_type: { id: "" },
        address: "",
        postal_code: "",
        locality: "",
        state: "",
        country: { id: "" },
        phone: "",
        email: "",
        phone_alternative: "",
        fax: "",
    },
}

const LEGAL_PERSON_CONTACT_FIELDS = [
    [
        {
            id: "legal_person_type",
            label: "contact.fields.legal_person_type",
            type: "select",
            schema_key: "legal_person_type",
            required: true
        },
        {
            id: "vat_number",
            label: "common.fields.vat_type.nif",
            type: "text",
            required: true
        },
    ],
    [
        {
            id: "name",
            label: "contact.fields.legal_person_name",
            type: "text",
            required: true
        },
    ],
]

const NATURAL_PERSON_CONTACT_FIELDS = [
    [
        {
            id: "name",
            label: "common.fields.contact.name",
            type: "text",
            required: true
        },
        {
            id: "surname",
            label: "common.fields.contact.surname",
            type: "text",
        },
    ],
    [
        {
            id: "position",
            label: "contact.fields.title",
            type: "text",
        },
        {
            id: "department",
            label: "contact.fields.department",
            type: "text",
        },
    ],
    [
        {
            id: "notify_closure",
            label: "contact.fields.notify_closure",
            type: "boolean",
            required: true
        },
        {
            id: "restrict_calls",
            label: "contact.fields.restrict_calls",
            type: "boolean",
            required: true
        },
    ],
    [

        {
            id: "contact_method",
            label: "contact.fields.contact_method",
            type: "text",
        },
    ],
    [
        {
            id: "language",
            label: "common.fields.language",
            type: "select",
            mapping: () => createLanguagesMapping(t, languagesCode2(), true),
            required: true
        },
    ]
]

const FORM_FIELDS = [
    [
        {
            id: "contact_details",
            label: null,
            type: "address",
        },
    ],
]

const processFields = (fields, showFields, copyAddress) => {
    if (!_isArray(showFields) || showFields.length === 0) {
        return fields
    }

    if (_isArray(fields)) {
        return fields.map((arrayFields) => arrayFields.filter((field) => showFields.includes(field.id) && (!copyAddress || field.id !== "contact_details")))
    }

    return fields.filter((field) => showFields.includes(field.id && (!copyAddress || field.id !== "contact_details")))
}

const getCopyAddressField = ({ value, schema, t, updateValue, copyAddresFrom }) => {
    return (
        <>
            {!_isEmpty(copyAddresFrom) && <FormRow
                key="copy_address"
                fields={[{
                    id: "copy_address",
                    label: t("common.fields.contact_details.copy_address", {
                        copy_address_from_type: t("common.fields.contact_details.copy_address_from_types." + copyAddresFrom)
                    }),
                    type: "checkbox",
                }]}
                value={value}
                schema={schema}
                mode={"full"}
                onChange={updateValue}
            />}
        </>
    )
}

const validate = (t, mainfield, value, id, contactType) => {
    if (!mainfield.required) {
        return null
    }

    if (!value) {
        return t("common.form.validation.required_contact")
    }

    if (contactType === "natural_person") {
        const notEmptyStringFields = ["name"]
        if (notEmptyStringFields.filter((field) => _isEmpty(_get(value, field, ""))).length > 0) {
            return t("contact.validation.required.name")
        }

        const notEmptyBooleanFields = ["notify_closure", "restrict_calls"]
        if (notEmptyBooleanFields.filter((field) => _get(value, field) === "").length > 0) {
            return t("contact.validation.required.notifications")
        }
    } else {
        const notEmptyStringFields = ["vat_number", "legal_person_type", "name"]
        let errorMsg = ""
        notEmptyStringFields.forEach((field) => {
            if (_isEmpty(_get(value, field, ""))) {
                errorMsg = t("contact.validation.required." + field)
            }
        })
        if (!_isEmpty(errorMsg)) {
            return errorMsg
        }
    }

    return null
}

const getOptions = (options) => {
    return Object.entries(options).map(([key, value]) => {
        return { value: key, label: _capitalize(value) }
    })
}

const FormField = ({ field, value, schema, onChange }) => {
    const { t } = useTranslation("vbms")

    if (field.type === "select" || field.type === "boolean") {
        let options = []
        switch (field.type) {
            case "select":
                if (field.mapping) {
                    options = getOptions(field.mapping())
                } else {
                    options = getOptions(schema[field.schema_key].options)
                }
                break
            case "boolean":
                options = [
                    {
                        label: " - ",
                        value: "",
                    },
                    {
                        label: t("common.yes"),
                        value: true,
                    },
                    {
                        label: t("common.no"),
                        value: false,
                    }
                ]
                break
            default:
                break
        }
        return (
            <FormControl required={field.required} fullWidth>
                <InputLabel id={field.id} htmlFor={field.id} shrink>{t(field.label)}</InputLabel>
                <Select
                    labelId={field.id}
                    id={field.id}
                    value={value}
                    label={t(field.label)}
                    onChange={(e) => onChange(e.target.value)}
                    displayEmpty
                    size="small"
                    notched={true}
                >
                    {options.map((option, index) => {
                        return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    } else if (field.type === "text") {
        return (
            <TextField
                id={field.id}
                label={t(field.label)}
                value={value}
                required={field.required}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                size="small"
                InputLabelProps={{
                    shrink: true
                }}
            />
        )
    } else if (field.type === "address") {
        field.optionalFields = false
        return (
            <AddressFormField
                field={field}
                value={value}
            />
        )
    } else if (field.type === "checkbox") {
        return (
            <FormControlLabel
                id={field.id}
                control={<Checkbox onChange={(e) => onChange(e.target.checked)} />}
                label={t(field.label)}
                variant="outlined"
                size="small"
            />
        )
    }

    return null
}

const FormRow = ({ fields, value, schema, mode, onChange }) => {
    return (
        <Grid item container spacing={2}>
            {fields.map((field, index) => (
                <Grid item xs={12} md={(fields.length === 1 ? 12 : 6)} key={index}>
                    <FormField
                        field={field}
                        value={_get(value, field.id, "")}
                        schema={schema}
                        onChange={(value) => onChange(field.id, value)}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

const ContactForm = ({ id, contactType, showFields, copyAddresFrom, defaultValue, required, onCopyAddressChange, onChange }) => {
    const [value, setValue] = useState(defaultValue || BASE_CONTACT_VALUE)
    const [schema, setSchema] = useState(false)
    const cache = useCache()

    const legalPersonContactFields = processFields(LEGAL_PERSON_CONTACT_FIELDS, showFields)
    const naturalPersonContactFields = processFields(NATURAL_PERSON_CONTACT_FIELDS, showFields)
    const formFields = processFields(FORM_FIELDS, showFields, value.copy_address)

    useEffect(() => {
        cache.get("contact_schema").then((schema) => {
            setSchema(schema)
        })
    }, [cache, setSchema])

    useEffect(() => {
        onChange(value)
    }, [value])

    const updateValue = useCallback((field, value) => {
        setValue((prevValue) => {
            const newValue = {
                ...prevValue,
            }
            _set(newValue, field, value)

            return newValue
        })

        if (field === "copy_address" && _isFunction(onCopyAddressChange)) {
            onCopyAddressChange({
                copy_address: value,
                copy_address_from: copyAddresFrom
            })
        }
    }, [setValue, onCopyAddressChange])

    return (
        <Box>
            <SimpleLoading loading={!schema} />
            {schema && <Grid spacing={2} container>
                {(contactType === "legal_person") && legalPersonContactFields.map((row, index) => {
                    const fields = row.map((field) => {
                        const result = {
                            ...field,
                            required: field.required && required
                        }

                        return result
                    })

                    return (
                        <FormRow
                            key={index}
                            fields={fields}
                            value={value}
                            schema={schema}
                            mode={(index % 2 === 0 && row.length > 1) ? "compact" : "full"}
                            onChange={updateValue}
                        />
                    )
                })}
                {(contactType === "natural_person") && naturalPersonContactFields.map((row, index) => {
                    const fields = row.map((field) => {
                        const result = {
                            ...field,
                            required: field.required && required
                        }

                        return result
                    })

                    return (
                        <FormRow
                            key={index}
                            fields={fields}
                            value={value}
                            schema={schema}
                            mode={(index % 2 === 0 && row.length > 1) ? "compact" : "full"}
                            onChange={updateValue}
                        />
                    )
                })}
                {getCopyAddressField({ value, schema, t, updateValue, copyAddresFrom })}
                {formFields.map((row, index) => {
                    const fields = row.map((field) => {
                        const result = {
                            ...field,
                            required: field.required && required
                        }

                        if (field.type === "address") {
                            result.id = id + "." + result.id
                            //result.onChange = (field, newValue) => {

                            // if (value.contact_details.copy_address_from !== newValue.copy_address_from) {
                            //     updateValue(value.contact_details, newValue)
                            // }
                            //}

                            // if (_isFunction(configField.onChange)) {
                            //         configField.onChange(field.name, value)
                            //     }
                        }

                        return result
                    })

                    return (
                        <FormRow
                            key={index}
                            fields={fields}
                            value={value}
                            schema={schema}
                            mode={(index % 2 === 0 && row.length > 1) ? "compact" : "full"}
                            onChange={updateValue}
                        />
                    )
                })}
            </Grid>}
        </Box>
    )
}

const ContactFormField = ({ field, value }) => {

    const { t } = useTranslation("vbms")

    const configField = field

    return (
        <Field
            id={field.id}
            name={field.id}
            validate={(value) => validate(t, field, value, configField.id, configField.contact_type)}
        >
            {({
                field,
                form: { value, setFieldValue, setFieldTouched },
                meta,
            }) => {

                return (
                    <>
                        <ContactForm
                            id={configField.id}
                            contactType={configField.contact_type}
                            showFields={configField.showFields}
                            copyAddresFrom={configField.copyAddresFrom}
                            defaultValue={meta.initialValue}
                            required={configField.required}
                            onCopyAddressChange={configField.onCopyAddressChange}
                            onChange={(value) => {
                                setFieldValue(field.name, value, true)
                                setFieldTouched(field.name, true, false)
                            }}
                        />
                        {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                    </>
                )
            }}
        </Field>
    )
}

export default ContactFormField