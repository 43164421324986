import CollectionDataTable from "../../Datatable/CollectionDataTable"
import {getExperiencesFilter} from '../../../lib/collection-filters';
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"

const ExperiencesTable = ({ defaultParams, ignoreSearchParams }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()    
    
    const experiencesFetcher = getExperiencesFilter(apiClient, t, user, false, defaultParams)

    return (
        <CollectionDataTable
            ignoreSearchParams={ignoreSearchParams}
            fetcher={experiencesFetcher.table.fetcher}
            columns={experiencesFetcher.table.columns}
            filterConfig={experiencesFetcher.table.filterConfig}
        />
    )
}

export default ExperiencesTable