import ExperiencesTable from "../../../../../../components/Volcano/Experiences/ExperiencesTable";
import apiConstants from "../../../../../../lib/api-constants"
import _has from "lodash/has"

const TagExperiences = ({ entity }) => {

    if (!entity) {
        return null;
    }
    
    const paramName = (_has(entity, "site") && entity.site.is_admin) ? "internal_tag_id" :"tag_id"
        
    return (
        
        <ExperiencesTable 
            ignoreSearchParams={true}
            defaultParams={{[paramName]: entity.id }} 
        />
        
    )
}

export default TagExperiences