import { Alert, Box } from '@mui/material';
import availableRoutes from '../../../../routes/availableRoutes'
import catalogRoutes from "../../config/routes";
import ProductAddTag from './components/actions/ProductAddTag';

const changeStateActionTemplate = (state, apiClient, t) => {
    const actionKey = `change_state_${state}`

    return {
        id: actionKey,
        title: t(`catalog.products.actions.${actionKey}.button`),
        confirmDialog: {
            title: t(`catalog.products.actions.${actionKey}.modal_title`),
            content: (selection) => (
                <Box>
                    <Box mb={1}>{t(`catalog.products.actions.${actionKey}.modal_content`, { name: selection.name })}</Box>
                    <Alert severity="warning">{t(`catalog.products.actions.${actionKey}.modal_content_alert`)}</Alert>
                </Box>
            ),
        },
        reloadOnSuccess: true,
        onExecute: (product, progressHandler, onSuccess, onError) => {
            return apiClient.catalog.product.editProduct(product.id, { state: state })
                .then((product) => {
                    onSuccess(
                        t(`catalog.products.actions.${actionKey}.confirm`, {
                            name: product.name,
                        })
                    )

                    return product
                })
                .catch((error) => {
                    onError(
                        t(`catalog.products.actions.${actionKey}.error`, {
                            name: product.name,
                        })
                    )

                    throw error
                })
        },
        condition: (product) => product.hasAction("change_state") && product.state !== state,
    }
}
export const getActions = (apiClient, t, itemProps, navigate, user, entityId = null) => {

    return [
        changeStateActionTemplate("active", apiClient, t, entityId),
        changeStateActionTemplate("inactive", apiClient, t, entityId),
        {
            id: "edit",
            title: t("catalog.products.actions.edit.button"),
            onExecute: (product) => {
                return Promise.resolve(product)
                    .then((product) => {
                        navigate(availableRoutes.catalog_products_edit.path.replace(":entityId", entityId ?? product.id))
                    })
            },
            condition: (product) => product.hasAction("edit")
        },
        {
            id: "translate",
            title: t("catalog.products.actions.edit_content.button"),
            onExecute: (product) => {
                const baseUrl = user.settings.disfrutare.base_url
                const loginUrl = user.settings.disfrutare.login_url
                const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

                window.open(url.replace("[URL]", catalogRoutes.disfrutare_product_translate.path.replace(":entityId", product.id)))
            },
            condition: (product) => product.hasAction("edit")
        },
        {
            id: "availability",
            title: t("catalog.products.actions.availability.button"),
            onExecute: (product) => {
                const baseUrl = user.settings.disfrutare.base_url
                const loginUrl = user.settings.disfrutare.login_url
                const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

                window.open(url.replace("[URL]", catalogRoutes.disfrutare_availability_products.path.replace(":entityId", ":" + product.id)))
            },
            condition: (product) => product.hasAction("edit_availability")
        },
        {
            id: "add_tag",
            title: t("catalog.products.actions.add_tag.button"),
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("catalog.products.actions.add_tag.modal_title"),
                content: ({ selection, onAction, onClose }) =>
                    <ProductAddTag
                        selection={selection}
                        internalTag={false}
                        onAction={onAction}
                        onClose={onClose}
                    />,
                maxWidth: "lg",
            },
            onExecute: (data, progressHandler, onSuccess, onError) => {
                const tags = {
                    add: data.tags.map((tag) => ({ id: tag.id }))
                }

                return apiClient.catalog.product.addTags(entityId, tags)

                    .then((data) => {
                        onSuccess(t("catalog.products.actions.add_tag.confirm"))
                        navigate(catalogRoutes.catalog_products_view.path.replace(":entityId", entityId))
                    })
                    .catch((error) => {
                        onError(t("catalog.products.actions.add_tag.error"))
                        throw error
                    })
            },
            condition: (product) => product.hasAction("edit_tags")
        },
        {
            id: "add_internal_tag",
            title: t("catalog.products.actions.add_internal_tag.button"),
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("catalog.products.actions.add_internal_tag.modal_title"),
                content: ({ selection, onAction, onClose }) =>
                    <ProductAddTag
                        selection={selection}
                        internalTag={true}
                        onAction={onAction}
                        onClose={onClose}
                    />,
                maxWidth: "lg",
            },
            onExecute: (data, progressHandler, onSuccess, onError) => {
                const internalTags = {
                    add: data.tags.map((tag) => ({ id: tag.id }))
                }
                return apiClient.catalog.product.addInternalTags(entityId, internalTags)
                    .then((data) => {
                        onSuccess(t("catalog.products.actions.add_internal_tag.confirm"))
                        navigate(catalogRoutes.catalog_products_view.path.replace(":entityId", entityId))
                    })
                    .catch((error) => {
                        onError(t("catalog.products.actions.add_internal_tag.error"))
                        throw error
                    })
            },
            condition: (product) => product.hasAction("edit_internal_tags")
        },
    ]
}
