import constants from '../../../../components/entity/constants';
import { createField, createDataSetFacet, createFacetsContainer, createFacetField, createObjectStatusField, createCallbackField } from '../../../../components/helpers/fieldHelper';


export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("tags.view.facet_information"), [
        createField(
            "id",
            "Id",
            constants.STRING_TYPE
        ),        
        createField(
            "slug",
            t("tags.fields.slug"),
            constants.STRING_TYPE,
            null
        ),        
        createCallbackField(
            "site.name",
            t("tags.fields.site"),
            constants.STRING_TYPE
        ),
        createCallbackField(
            "group.name",
            t("tags.fields.group"),
            constants.STRING_TYPE,
            (value) => value || t("tags.constants.no_group")
        ),
        createField(
            "created",
            t("common.fields.created"),
            constants.DATETIME_TYPE
        )
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createCallbackField(
                    "visible",
                    t("tags.fields.visible"),
                    constants.BOOLEAN_TYPE),
                null,
                (value) => value ? "positive" : "negative"
            ),
        }),
        createFacetField("object_status", {
            size: "small",
            field: createObjectStatusField(
                createCallbackField(
                    "protected",
                    t("tags.fields.protected"),
                    constants.BOOLEAN_TYPE
                ),
                null,
                (value) => value ? "positive" : "negative"
            ),
        }),
    ]),
]