/* eslint-disable no-sparse-arrays */
import availableRoutes from "../../../../routes/availableRoutes"
import userPermissions from "../../../../lib/user-permissions"
import ExperienceProductsForm from './components/actions/ExperienceProductsForm'
import PublishIcon from "@mui/icons-material/Publish";
export const getActions = (apiClient, t, user, itemProps, navigate, entityId = null) => {
    const { scope, hideIcons, reloadOnSuccess } = { ...itemProps }

    return [
        {
            id: "edit",
            title: t("catalog.experiences.actions.manage.button"),
            onExecute: (experience) => {
                const baseUrl = user.settings.disfrutare.base_url
                const loginUrl = user.settings.disfrutare.login_url
                const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

                window.open(url.replace("[URL]", availableRoutes.disfrutare_experience_edit.path.replace(":entityId", experience.id)))
            },
            condition: () => user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)
        },
        {
            id: "set_experience_products",
            scope: scope,
            icon: hideIcons ? null : PublishIcon,
            title: t("catalog.experiences.actions.set_experience_products.button"),
            withDialog: {
                title: (experience) => t("catalog.experiences.actions.set_experience_products.modal_title", { name: experience.name }),
                content: ExperienceProductsForm
            },
            showLoading: true,
            reloadOnSuccess: true,
            onExecute: (data, progressHandler, onSuccess, onError) => {
                return apiClient.catalog.experience.setExperienceProducts(data.experience.id, data.products)
                    .then((result) => {
                        onSuccess(
                            t("sites.actions.set_featured_products.confirm", { name: data.experience.name })
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("sites.actions.set_featured_products.error", { name: data.experience.name })
                        )
                    })
            },
        },
        {
            id: "translate",
            title: t("catalog.experiences.actions.translate.button"),
            onExecute: (experience) => {
                const baseUrl = user.settings.disfrutare.base_url
                const loginUrl = user.settings.disfrutare.login_url
                const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

                window.open(url.replace("[URL]", availableRoutes.disfrutare_experience_translate.path.replace(":entityId", experience.id)))
            },
            condition: () => user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)
        },
        // {
        //     id: "change_state_inactive",
        //     title: t("catalog.experiences.actions.change_state_inactive.button"),
        //     confirmDialog: {
        //         title: t("catalog.experiences.actions.change_state_inactive.modal_title"),
        //         content: (experience) =>
        //             t("catalog.experiences.actions.change_state_inactive.modal_content", {
        //                 name: experience.name
        //             })
        //     },
        //     reloadOnSuccess: reloadOnSuccess,
        //     showLoading: true,
        //     onExecute: (result, progressHandler, onSuccess, onError) => {
        //         let experience = { ...result, actions: ['toggleState'] }

        //         return apiClient.catalog.experience.editExperience(experience.id, experience)
        //             .then((experience) => {
        //                 onSuccess(
        //                     t("catalog.experiences.actions.change_state_inactive.confirm", {
        //                         name: experience.name
        //                     }),
        //                 )
        //                 navigate(availableRoutes.catalog_experiences_view.path.replace(":entityId", experience.id))
        //             })
        //             .catch((error) => {
        //                 onError(
        //                     t("catalog.experiences.actions.change_state_inactive.error", {
        //                         name: experience.name,
        //                     }))
        //             })
        //     },
        //     condition: (experience) => experience.hasAction("edit") && user.hasPermission(userPermissions.PERM_CATALOG_ADMINISTER)
        // },
    ]
}
