import { useAsyncContent } from "../../../../../../context/async.context"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import AsyncEntityProductRatesTable from "../../../../components/ProductsTable"

const RateGroupProducts = ({ entity }) => {
    const { fetchContent } = useAsyncContent()
    const { apiClient } = useVolcanoApiClient()

    if (!entity) {
        return null;
    }

    const fetcher = () => 
        apiClient.catalog.rateGroup.getRateGroupProducts(entity.id, {limit: 1000})
        .then((collection) => {
            return collection.getItems().map((product) => {
                product.container = {
                    id: entity.id,
                    name: entity.name,
                }

                return product
            })
    }) 

    const actionHandlers = {
        sort_experiences: (containerId, data) => apiClient.catalog.rateGroup.editRateGroupExperiences(containerId, {experiences: data.sorted_experiences}),
        edit: (containerId, productId, data) => apiClient.catalog.rateGroup.editRateGroupProduct(containerId, productId, data),
        sync: (productRatesPublicationId, containerId, productId) => apiClient.catalog.productRatesPublication.editProductRatePublicationProducts(productRatesPublicationId, containerId, "rate_group", {add: [{id: productId}]}),
        delete: (containerId, productId) => apiClient.catalog.rateGroup.deleteRateGroupProduct(containerId, productId),
    }

    return <AsyncEntityProductRatesTable entity={entity} fetcher={fetcher} containerFetcher={fetchContent} actionHandlers={actionHandlers} hideCustomConditions={true} />
}

export default RateGroupProducts