import availableRoutes from "../../../../routes/availableRoutes"
import { getActions as getRowActions } from "../TagPage/TagPage.actions"

export const getActions = (t, navigate, user) => [
    {
        title: t("common.create"),
        onExecute: () => {
            navigate(availableRoutes.tags_add.path)
        },
        condition: () => user.hasAnyPermission([
            'administer-tags-system',
            'administer-tags',
            'administer-admin-tags',
        ])
    },
]


export const getTableActions = (...props) => getRowActions(...props)