import React from "react"
import { Field, FieldArray } from "formik"
import { Box, Stack, Typography, IconButton } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import { Container, Draggable } from "react-smooth-dnd"
import constants from "../entity/constants"
import {TextField} from "formik-mui"
import {isNumber} from "lodash"


const DynamicValuesListField = ({ field, form, keyIndex, valueName, typeValue }) => {

    return (
        <FieldArray name={field.name}>
            {(arrayHelpers) => {
                const values = Array.isArray(field.value) ? field.value : []

                const handleAdd = () => {
                    arrayHelpers.push({
                        [keyIndex]: values.length + 1,
                        [valueName]: "",
                    })
                }

                const validateValue = (value) => {
                    if (value === null || value === "") {
                        return true
                    }
                    return !!(!isNumber(value) && value < 0)
                }

                const handleBlurCurrency = (idx, e) => {
                    let val = e.target.value

                    if (typeof val === "string" && val.trim().endsWith(".")) {
                        val = val.slice(0, -1)
                    }

                    const parsed = parseAmount(val)
                    form.setFieldValue(`${field.name}.${idx}.${valueName}`, parsed)
                }

                const handleDrop = ({ removedIndex, addedIndex }) => {
                    if (removedIndex == null && addedIndex == null) return
                    const newValues = [...values]
                    const [removedItem] = newValues.splice(removedIndex, 1)
                    newValues.splice(addedIndex, 0, removedItem)

                    const reIndexed = newValues.map((item, i) => ({
                        ...item,
                        [keyIndex]: i + 1,
                    }))
                    form.setFieldValue(field.name, reIndexed)
                }

                const parseAmount = (amount) => {
                    let nAmount = amount.endsWith(",") ? amount.replace(",", ".") : parseFloat(amount.replace(",", ".")) // parse to float

                    nAmount = isNaN(nAmount) && amount.length > 0 && !amount.endsWith(".") ? "" : nAmount // remove other chars than decimal separator

                    return isNaN(nAmount) || amount.endsWith(".") ? amount : nAmount
                }

                const handleChangeNumeric = (idx, newValue) => {
                    if (newValue !== "" && newValue !== null) {
                        const isInteger = /^\d+$/.test(newValue)

                        if (!isInteger) {
                            return
                        }
                        form.setFieldValue(`${field.name}.${idx}.${valueName}`, parseInt(newValue, 10))
                    } else {
                        form.setFieldValue(`${field.name}.${idx}.${valueName}`, null)
                    }
                }

                const handleChangeCurrency = (idx, newValue) => {
                    if (newValue !== "" && newValue !== null) {
                        newValue = parseAmount(newValue)
                        form.setFieldValue(`${field.name}.${idx}.${valueName}`, newValue)

                    } else {
                        form.setFieldValue(`${field.name}.${idx}.${valueName}`, null)
                    }
                }
                const handleDelete = (idx) => {
                    arrayHelpers.remove(idx)

                    const newValues = [...values]
                    newValues.splice(idx, 1)
                    const reIndexed = newValues.map((item, i) => ({
                        ...item,
                        [keyIndex]: i + 1,
                    }))
                    form.setFieldValue(field.name, reIndexed)
                }



                return (
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ height: 40 }}>
                        <Container
                            dragHandleSelector=".drag-handle"
                            lockAxis="x"
                            onDrop={handleDrop}
                            orientation="horizontal"
                        >
                            {values.map((item, idx) => (
                                <Draggable key={`${item[keyIndex]}-${idx}`}>
                                    <Box
                                        sx={{
                                            height: 40,
                                            border: 0,
                                            borderRadius: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            overflow: "hidden",
                                            mr: 1,
                                        }}
                                    >
                                        <Box
                                            className="drag-handle"
                                            sx={{
                                                bgcolor: "grey.100",
                                                flex: "0 0 auto",
                                                px: 2,
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "grab",
                                                height: "100%",
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{ fontWeight: "bold", lineHeight: 1.2 }}
                                            >
                                                {item[keyIndex]}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                flex: 1,
                                                px: 0,
                                                display: "flex",
                                                alignItems: "center",
                                                height: "100%",
                                            }}
                                        >
                                            {typeValue === constants.CURRENCY_TYPE && (
                                                <Box display="inline-flex">
                                                    <Field
                                                        component={TextField}
                                                        id={idx.toString()}
                                                        value={item[valueName]}
                                                        name={`${field.name}.${idx}.${valueName}`}
                                                        onChange={(e) => handleChangeCurrency(idx, e.target.value)}
                                                        InputProps={{
                                                            size: "small",
                                                            inputMode: 'numeric'
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                        error={validateValue(item[valueName])}
                                                        onBlur={(e) => handleBlurCurrency(idx, e)}
                                                    />
                                                </Box>
                                            )}
                                            {typeValue === constants.NUMERIC_TYPE && <Field
                                                id={idx.toString()}
                                                component={TextField}
                                                name={`${field.name}.${idx}.${valueName}`}
                                                label=""
                                                onChange={(e) => handleChangeNumeric(idx, e.target.value)}
                                                InputProps={{
                                                    size: "small",
                                                    inputMode: 'numeric',
                                                    pattern: "[0-9]*"
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                error={validateValue(item[valueName])}
                                            />}
                                        </Box>

                                        <Box
                                            sx={{
                                                flex: "0 0 auto",
                                                px: 0,
                                                display: "flex",
                                                alignItems: "center",
                                                height: "100%",
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => handleDelete(idx)}
                                                color="error"
                                                size="small"
                                                sx={{ p: 0.5 }}
                                            >
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Draggable>
                            ))}
                        </Container>

                        <IconButton
                            onClick={handleAdd}
                            color="primary"
                            size="small"
                            sx={{ height: 40, p: 1 }}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                )
            }}
        </FieldArray>
    )
}

const DynamicValuesListFormField = ({ field }) => {
    const configField = field

    return (
        <Field name={field.name}>
            {({ field, form }) => (
                <DynamicValuesListField
                    field={field}
                    form={form}
                    keyIndex={configField.keyIndex}
                    valueName={configField.valueName}
                    typeValue={configField.typeValue}
                />
            )}
        </Field>
    )
}

export default DynamicValuesListFormField
