import { Box, Checkbox, CircularProgress, FormControlLabel, MenuItem, Stack, TextField } from "@mui/material"
import DataContainer from "../../../components/Display/DataContainer"
import ProductRatesTable from "./ProductRatesTable"
import { useCallback, useEffect, useState } from "react"
import { filterAvailableActions } from "../../../components/action/utils"
import { useTranslation } from "react-i18next"
import { getProductRatesTableActions } from "./product-rates-utils"
import { createCommonBooleanMapping, createCommonVisibilityMapping, createOptionsMapping } from "../../../components/helpers/fieldHelper"
import BaseActionsGroup from "../../../components/action/BaseActionsGroup"
import _isFunction from "lodash/isFunction"

const AVAILABLE_CONTAINER_ACTIONS = ['sort_experiences']
const AVAILABLE_PRODUCT_ACTIONS = ['add', 'edit', 'sync', 'delete']

const processActions = (actions, refresh) => {
    return (actions || []).map((action) => {
        if (action?.reloadOnSuccess) {
            return {
                ...action,
                onExecute: (data, progressHandler, onSuccess, onError) => {
                    const reloadFetcher = _isFunction(action.reloadFetcher) ? action.reloadFetcher : refresh
                    return action.onExecute(data, progressHandler, onSuccess, onError).then(() => reloadFetcher())
                }
            }
        }

        return action
    })
}

const initFilter = (filter) => {
    const defaultValues = {
        product: "",
        active: "all",
        visibility: "all",
        custom: false,
    }

    if (filter) {
        return {
            ...defaultValues,
            ...filter
        }
    }
}

/**
 * Select field with options
 * @param {*} param0 
 */
const SelectFilterField = ({ label, options, onChange }) => {
    const handleChange = (e) => {
        onChange(e.target.value)
    }

    return (
        <TextField
            select
            variant="outlined"
            size="small"
            label={label}
            defaultValue="all"
            onChange={handleChange}
            sx={{ width: 100 }}
        >
            {options.map((value) => (
                <MenuItem key={value.value} value={value.value}>{value.label}</MenuItem>
            ))}
        </TextField>
    )
}

/**
 * Filter products by name and returns the ids of the products that match the filter
 * 
 * @param {function} onFilter 
 * @returns 
 */
const ProductsFilter = ({ container, onFilter, hideCustomConditions, actions }) => {
    const { t } = useTranslation("vbms")
    const [filter, setFilter] = useState(initFilter({}))
    const [debouncedProductFilter, setDebouncedProductFilter] = useState("")
    
    useEffect(() => {
        onFilter(filter)
    }, [filter, onFilter])

    // debounce product filter text input
    useEffect(() => {
        const timer = setTimeout(() => {
            setFilter(prevFilter => ({
                ...prevFilter,
                product: debouncedProductFilter
            }))
        }, 300) // 300ms debounce delay
        
        return () => clearTimeout(timer)
    }, [debouncedProductFilter])

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",

        }}>
            <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                <TextField
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    label={t("catalog.rates_table.filter.products")}
                    value={debouncedProductFilter}
                    onChange={(e) => {
                        setDebouncedProductFilter(e.target.value)
                    }}
                />

                <SelectFilterField
                    label={t("catalog.rates_table.rates.active")}
                    options={createOptionsMapping(t, createCommonBooleanMapping(t), true)}
                    onChange={(value) => {
                        setFilter((prevFilter) => ({
                            ...prevFilter,
                            active: value
                        }))
                    }}
                />

                <SelectFilterField
                    label={t("common.visibility.title")}
                    options={createOptionsMapping(t, createCommonVisibilityMapping(t), true)}
                    onChange={(value) => {
                        setFilter((prevFilter) => ({
                            ...prevFilter,
                            visibility: value
                        }))
                    }}
                />

                {!hideCustomConditions && (
                    <FormControlLabel
                        control={<Checkbox />}
                        label={t("catalog.rates_table.filter.custom")}
                        onChange={(e) => {
                            setFilter((prevFilter) => ({
                                ...prevFilter,
                                custom: e.target.checked
                            }))
                        }}
                    />
                )}
            </Stack>
            <BaseActionsGroup
                selection={container}
                variant="outlined"
                actions={actions}
                actionsNumberDesktop={2}
            />
        </Box>
    )
}

const ProductsTable = ({ container, products, hideFilter, hideCustomConditions, actions }) => {
    const { i18n } = useTranslation("vbms")
    const [filteredProducts, setFilteredProducts] = useState(products)

    const onFilterHandler = useCallback((filter) => {
        if (filter) {
            const productFilter = filter.product && filter.product.length > 0 ? filter.product.toLowerCase() : null
            const filteredProducts = products.filter((product) => {
                let result = true
                if (filter.custom && !product.custom_configuration) {
                    result = false
                }

                if (productFilter) {
                    result = result && (
                        product.name.toLowerCase().includes(productFilter) ||
                        product.experience.name.toLowerCase().includes(productFilter)
                    )
                }

                if (filter.active !== "all") {
                    result = result && product.active === Boolean(filter.active === "true")
                }

                if (filter.visibility !== "all") {
                    result = result && product.visibility === filter.visibility
                }

                return result
            })

            setFilteredProducts(filteredProducts)
        } else {
            setFilteredProducts(products)
        }
    }, [products])

    // sort products by name
    const sortedProducts = [...filteredProducts].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), i18n.language, { ignorePunctuation: true }))

    // group products by experience name
    const groupedProducts = sortedProducts.reduce((acc, product) => {
        const experienceName = product.experience.name
        if (!acc[experienceName]) {
            acc[experienceName] = []
        }
        acc[experienceName].push(product)
        return acc
    }, {})

    // if container is a rate group then sort experiences by configuration
    // else sort experiences by name
    let sortedExperiences = {}
    if (container?.sorted_experiences) {
        container.sorted_experiences.forEach((experience) => {
            if (groupedProducts[experience.name]) {
                sortedExperiences[experience.name] = groupedProducts[experience.name]
            }
        })
    } else {
        Object.keys(groupedProducts).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase(), i18n.language, { ignorePunctuation: true })).forEach((key) => {
            sortedExperiences[key] = groupedProducts[key]
        })
    }

    return (
        <Stack spacing={2} >
            {!hideFilter && (
                <ProductsFilter
                    container={container}
                    onFilter={onFilterHandler}
                    hideCustomConditions={hideCustomConditions}
                    actions={filterAvailableActions(actions, container, AVAILABLE_CONTAINER_ACTIONS)}
                />
            )}
            {sortedExperiences && Object.keys(sortedExperiences).map((experienceName) => (
                <DataContainer key={experienceName} title={experienceName} titleLevel="h4">
                    {groupedProducts[experienceName].map((product) => (
                        <Box
                            key={product.id}
                            display="flex"
                            mb={4}
                        >
                            <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="center"
                                flexDirection="column"
                                flexGrow={1}
                            >
                                <ProductRatesTable product={product} actions={filterAvailableActions(actions, product, AVAILABLE_PRODUCT_ACTIONS)} />
                            </Box>
                        </Box>
                    ))}
                </DataContainer>
            ))}
        </Stack>
    )
}

const AsyncProductRatesTable = ({ container, fetcher, containerFetcher, actionHandlers, hideFilter, hideCustomConditions }) => {
    const [products, setProducts] = useState([])
    const [ready, setReady] = useState(false)
    const { t } = useTranslation("vbms")

    const refresh = () => {
        setReady(false)
        fetcher().then((products) => {
            setProducts(products)
            setReady(true)
        })
    }

    useEffect(() => {
        refresh()
    }, [fetcher])

    const actions = getProductRatesTableActions(container, actionHandlers, t, containerFetcher || refresh)

    return (
        <Box>
            {!ready && <Box display="inline-flex" alignItems="center"><CircularProgress size={20} /></Box>}
            {ready && <ProductsTable container={container} products={products} actions={processActions(actions, refresh)} hideFilter={hideFilter} hideCustomConditions={hideCustomConditions} />}
        </Box>
    )
}

const AsyncEntityProductRatesTable = ({ entity, fetcher, containerFetcher, actionHandlers, hideFilter, hideCustomConditions }) => {
    return <AsyncProductRatesTable
        container={entity}
        fetcher={fetcher}
        containerFetcher={containerFetcher}
        actionHandlers={actionHandlers}
        hideFilter={hideFilter}
        hideCustomConditions={hideCustomConditions}
    />
}


export default AsyncEntityProductRatesTable