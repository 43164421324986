import _isEmpty from "lodash/isEmpty"
import _unset from "lodash/unset"
import { format as formatDate } from "date-fns"
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createField, createFormField, createFormFieldset, createAsyncSelectFormField, createFormFieldOption, createCallbackField, createFormFieldGroup, createTextAreaFormField } from '../../../components/helpers/fieldHelper';
import AsyncEntityFormPage from "../../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import constants from "../../../components/entity/constants"
import apiConstants from "../../../lib/api-constants"
import useSnackBars from "../../../components/Snackbar/snack-bar.context";
import availableRoutes from "../../../routes/availableRoutes";
import { buildOptions } from "../../../lib/form-options-fetchers";

const FacilityBookLineFormPage = () => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()

    const makeTimeToDate = (value) => {
        if (value === null || value === "") {
            return null
        }

        let date = new Date();
        let [hours, minutes, seconds] = value.split(':').map(Number);

        date.setHours(hours || 0);
        date.setMinutes(minutes || 0);
        date.setSeconds(seconds || 0);

        return date

    }
    const makeDateToTime = (value) => {
        if (value === null || value === "") {
            return null
        }

        let hours = value.getHours().toString().padStart(2, '0');
        let minutes = value.getMinutes().toString().padStart(2, '0');
        let seconds = value.getSeconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }



    const fetcher = () => {
        if (!entityId) {
            navigate(availableRoutes.facility_book_lines.path)
        }

        return apiClient.venue.facilityBookLine.getFacilityBookLine(entityId).then((item) => {
            item.open_time = makeTimeToDate(item.open_time);
            item.close_time = makeTimeToDate(item.close_time);
            item.comments = item.comments?.replaceAll("<br />", "\n").replaceAll("\n\n", "\n").replaceAll("&nbsp;", "")
            item.driver_users = item.driver_users?.map((user) => user.id) || []
            item.line1_users = item.line1_users?.map((user) => user.id) || []
            item.line2_users = item.line2_users?.map((user) => user.id) || []
            item.cabin_users = item.cabin_users?.map((user) => user.id) || []
            item.officers2_users = item.officers2_users?.map((user) => user.id) || []
            item.service_personnel_users = item.service_personnel_users?.map((user) => user.id) || []
            item.public_relation_users = item.public_relation_users?.map((user) => user.id) || []
            item.information_point_users = item.information_point_users?.map((user) => user.id) || []
            return item
        })
    }
    const fetcherFacilityBookState = async (apiClient, t) => {
        let options = []
        const facilityBookStates = await apiClient.venue.facilityBookLine.getFacilityStates()

        facilityBookStates.forEach(item => {
            let option = createFormFieldOption(item[0], item[1])
            options.push(option)
        })

        return Promise.resolve(options)
    }

    const fetcherUser = (rolId) => {
        return apiClient.user.getApplicationUsers({ role_id: rolId, limit: 100 }).then((rolUsers) => {
            return buildOptions(rolUsers.getItems(), t, "id", "name", false)
        })
    }

    const getObjetIds = (users) => {
        let usersId = []
        users.forEach((item) => {
            usersId.push({
                id: item
            })
        })
        return usersId;
    }

    const onSaveHandler = (data) => {
        const facilityData = {
            ...data
        }
        if (_isEmpty(facilityData.responsable_user) || facilityData.responsable_user.id === "") {
            _unset(facilityData, "responsable_user")
        }

        facilityData.blocked = Boolean(data.blocked)
        facilityData.open_time = makeDateToTime(data.open_time)
        facilityData.close_time = makeDateToTime(data.close_time)
        facilityData.facility_book_state.id = Number(data.facility_book_state.id)
        facilityData.driver_users = getObjetIds(data.driver_users)
        facilityData.line1_users = getObjetIds(data.line1_users)
        facilityData.line2_users = getObjetIds(data.line2_users)
        facilityData.cabin_users = getObjetIds(data.cabin_users)
        facilityData.officers2_users = getObjetIds(data.officers2_users)
        facilityData.service_personnel_users = getObjetIds(data.service_personnel_users)
        facilityData.public_relation_users = getObjetIds(data.public_relation_users)
        facilityData.information_point_users = getObjetIds(data.information_point_users)

        apiClient.venue.facilityBookLine.editFacilityBookLines(entityId, facilityData)
            .then((data) => {
                navigate(`${availableRoutes.facility_book_lines.path}/${entityId}`)
            })
            .catch((error) => {
                addAlert("Error: " + error.message)
            })
    }

    const formContent = [
        createFormFieldset(t("facility_book_lines.form.fieldset_information"), [
            createFormFieldGroup([
                createFormField(
                    createField(
                        "open_time",
                        t("facility_book_lines.fields.open_time"),
                        constants.TIME_TYPE,
                    ),
                    true
                ),
                createField(
                    "close_time",
                    t("facility_book_lines.fields.close_time"),
                    constants.TIME_TYPE
                ),

            ]),
            createFormField(
                createField(
                    "blocked",
                    t("facility_book_lines.fields.blocked"),
                    constants.BOOLEAN_TYPE
                ),
                true
            ),
            createAsyncSelectFormField(
                "facility_book_state.id",
                t("facility_book_lines.fields.facility_book_state"),
                () => fetcherFacilityBookState(apiClient, t),
                null, null, null, true

            ),
            createAsyncSelectFormField(
                "responsable_user.id",
                t("facility_book_lines.fields.responsable_user"),
                () => fetcherUser(apiConstants.ROLE_VENUES_ADMINISTER_ID),
            ),
            createTextAreaFormField(
                "comments",
                t("facility_book_lines.fields.comments"),
                10,
            )
        ]),
        createFormFieldset(t("facility_book_lines.form.fieldset_personnel"), [
            {
                ...createAsyncSelectFormField(
                    "driver_users",
                    t("facility_book_lines.fields.drivers"),
                    () => fetcherUser(apiConstants.ROLE_DRIVER_ID),
                    true,
                    null,
                    (value) => value,
                ),
                viewMode: "chip",
            },
            {
                ...createAsyncSelectFormField(
                    "line1_users",
                    t("facility_book_lines.fields.line_responsables") + " 1",
                    () => fetcherUser(apiConstants.ROLE_VENUES_REVIEWER_ID),
                    true,
                    null,
                    (value) => value,
                ),
                viewMode: "chip",
            },
            {
                ...createAsyncSelectFormField(
                    "line2_users",
                    t("facility_book_lines.fields.line_responsables") + "2 ",
                    () => fetcherUser(apiConstants.ROLE_VENUES_REVIEWER_ID),
                    true,
                    null,
                    (value) => value,
                ),
                viewMode: "chip",
            },
            {
                ...createAsyncSelectFormField(
                    "cabin_users",
                    t("facility_book_lines.fields.cabins"),
                    () => fetcherUser(apiConstants.ROLE_CABIN_ID),
                    true,
                    null,
                    (value) => value,
                ),
                viewMode: "chip",
            },
            {
                ...createAsyncSelectFormField(
                    "officers2_users",
                    t("facility_book_lines.fields.officers_2"),
                    () => fetcherUser(apiConstants.ROLE_VENUES_OFFICER_ID),
                    true,
                    null,
                    (value) => value,
                ),
                viewMode: "chip",
            },
            {
                ...createAsyncSelectFormField(
                    "service_personnel_users",
                    t("facility_book_lines.fields.service_personnel"),
                    () => fetcherUser(apiConstants.ROLE_SERVICE_PERSONEL_ID),
                    true,
                    null,
                    (value) => value,
                ),
                viewMode: "chip",
            },
            {
                ...createAsyncSelectFormField(
                    "public_relation_users",
                    t("facility_book_lines.fields.public_relation"),
                    () => fetcherUser(apiConstants.ROLE_RRPP_ID),
                    true,
                    null,
                    (value) => value,
                ),
                viewMode: "chip",
            },
            {
                ...createAsyncSelectFormField(
                    "information_point_users",
                    t("facility_book_lines.fields.information_point"),
                    () => fetcherUser(apiConstants.ROLE_RRPP_ID),
                    true,
                    null,
                    (value) => value,
                ),
                viewMode: "chip",
            },
        ]),
    ]

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={
                createCallbackField(
                    "date",
                    t("facility_book_lines.form.title"),
                    constants.STRING_TYPE,
                    (entity, values) => t("facility_book_lines.form.title", {
                        facility_book_name: values.facility_book.name,
                        date: formatDate(new Date(values.date), "dd/MM/yyyy")
                    })
                )}
            onSave={onSaveHandler}
            formContent={formContent}
        />
    )

}

export default FacilityBookLineFormPage