import { createField, createFormField, createAsyncSelectFormField, createBooleanFormField, createDependentFormField } from "../../../../components/helpers/fieldHelper"
import { sitesFetcher } from "../../../../lib/form-options-fetchers"
import { buildOptions } from "../../../../lib/form-options-fetchers"
import constants from "../../../../components/entity/constants"
import _get from "lodash/get"

export const getTableFilter = (apiClient, t, user, cache) => {

    return ([
        createFormField(
            createField(
                "name",
                t("tags.filter.name"),
                constants.STRING_TYPE
            ),
            false
        ),
        createFormField(
            createField(
                "slug",
                t("tags.filter.slug"),
                constants.STRING_TYPE
            ),
            false
        ),
        createAsyncSelectFormField(
            "site_id",
            t("tags.filter.site"),
            (params) => sitesFetcher(cache, apiClient, params).then((sites) => buildOptions(sites, t)),
            false,
        ),
        createAsyncSelectFormField(
            "group_id",
            t("tags.filter.group"),
            (params) => cache.get("tag_groups").then((tagGroups) => buildOptions(tagGroups, t)),
            false,
        ),
        createBooleanFormField(
            t,
            "visible",
            t("tags.filter.visible")
        ),
        createBooleanFormField(
            t,
            "protected",
            t("tags.filter.protected"),
        ),
    ])
}
