import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import { getProductsFilter } from "../../../lib/collection-filters"
import EntitySelectorAction from '../../../components/form/EntitySelectorAction';

const NotificationTemplateAddProduct = ({ selection, onAction, onClose }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()

    const productFilter = getProductsFilter(apiClient, t, user, true, { state: "active", site_id: selection.site_id })

    return (
        <EntitySelectorAction
            table={productFilter.table}
            multiple={true}
            valueFormatter={productFilter.valueFormatter}
            onAction={onAction}
            onClose={onClose}

        />
    )
}

export default NotificationTemplateAddProduct 