import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import _isEmpty from 'lodash/isEmpty'
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import useSnackBars from "../../../components/Snackbar/snack-bar.context"
import { useCache } from "../../../context/cache.context"
import constants from "../../../components/entity/constants"
import { availabilityTypeMapping, createAsyncSelectFormField, createCallbackField, createDialogFormField, createField, createFormField, createFormFieldGroup, createFormFieldOption, createFormFieldset, createLanguagesMapping, createSelectFormField, languagesCode2, lowerPriceCriteriaMapping, validationTypeMapping } from "../../../components/helpers/fieldHelper"
import availableRoutes from "../../../routes/availableRoutes"
import { buildOptions, entitySchemaKeyItemsFetcher, remoteProductProvidersFetcher, transportsFetcher } from "../../../lib/form-options-fetchers"
import { getExperiencesFilter } from "../../../lib/collection-filters"
import AsyncEntityFormPage from "../../../layout/templates/AsyncContentPage/AsyncEntityFormPage"

const ProductFormPage = () => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { entityId } = useParams()
    const { user } = useVolcanoAuth()
    const { addAlert } = useSnackBars()
    const navigate = useNavigate()
    const cache = useCache()

    const stringToTime = (timeString) => {
        const [hours, minutes, seconds = 0] = timeString.split(':').map(Number);
        return new Date(new Date().setHours(hours, minutes, seconds, 0));
    };

    const timeToString = (date) =>
        [date.getHours(), date.getMinutes(), date.getSeconds()]
            .map(num => num.toString().padStart(2, '0'))
            .join(':');

    const fetcher = useCallback(() => {

        if (!entityId) {
            return new Promise((res) => res({}))
        }

        return apiClient.catalog.product.getProduct(entityId, false, { state: "all" }).then((product) => {

            // Format experience
            product.experiences = product.experience ? [{
                id: product.experience.id,
                label: product.experience.name
            }] : []

            // Format supplier
            product.supplier = product.supplier ? [{
                id: product.supplier.id,
                label: product.supplier.name
            }] : []

            // Format accountancy config
            product.accountancy_config = product.accountancy_config
                ? { id: product.accountancy_config.account_id }
                : null;

            product.availability_time_limit &&= stringToTime(product.availability_time_limit)

            return product
        })
    }, [apiClient, entityId])

    const onSaveHandler = (data) => {

        let product = { ...data }

        // Format to boolean
        product.with_confirmation = product.with_confirmation.toString() === "true";
        product.no_notification_to_reservations = product.no_notification_to_reservations.toString() === "true";
        product.in_inbox = product.in_inbox.toString() === "true";
        product.with_phone = product.with_phone.toString() === "true";
        product.with_sms_notifications = product.with_sms_notifications.toString() === "true";
        product.with_participants = product.with_participants.toString() === "true";

        // Format experience
        product.experiences = _isEmpty(product.experiences) ? [] : { id: product.experiences[0].id }

        // Format supplier
        product.supplier = _isEmpty(product.supplier) ? [] : { id: product.supplier[0].id }

        product.availability_time_limit = product.availability_time_limit && timeToString(product.availability_time_limit)

        // Format remote product provider
        product.remote_product_provider_id = product.remote_product_provider?.id ?? ""
        delete product?.remote_product_provider

        //Delete translatable fields 
        delete data.name
        delete data.meeting_point_name

        apiClient.catalog.product.editProduct(entityId, product, false)
            .then(() => {
                addAlert(t("catalog.products.actions.edit.confirm", "success"))
                navigate(availableRoutes.catalog_products_view.path.replace(":entityId", entityId))
            })
            .catch((error) => {
                addAlert(t("catalog.products.actions.edit.error", { name: error.message }))
            })
    }

    const formContent = [
        createFormFieldset(t("catalog.products.form.fieldset_information"), [
            createFormField(
                createField(
                    "sku",
                    t("catalog.products.fields.sku"),
                    constants.STRING_TYPE
                ),
                false
            ),
            createSelectFormField(
                "languages",
                t("catalog.products.fields.languages"),
                Object.entries(createLanguagesMapping(t, languagesCode2(), true))
                    .map(([key, value]) => (
                        {
                            value: key,
                            label: value
                        }
                    )),
                null,
                false,
                true
            ),
            createAsyncSelectFormField(
                "transport_id",
                t("catalog.products.fields.transport"),
                (params) => transportsFetcher(cache, apiClient, params).then((transports) => (
                    [
                        createFormFieldOption(0, t("catalog.products.constants.no_transport")),
                        ...buildOptions(transports, t, "id", "name", false)
                    ]
                )),
                false
            )
        ]),
        createFormFieldset(t("catalog.products.form.fieldset_associations"), [
            getExperiencesFilter(apiClient, t, false, 'experiences'),
            createFormField(
                createDialogFormField(
                    "supplier",
                    t("catalog.products.fields.supplier"),
                    false,
                    {
                        filterConfig: [
                            createField(
                                "name",
                                t("suppliers.fields.name"),
                                constants.TEXT_TYPE
                            )
                        ],
                        columns: [
                            createField(
                                "name",
                                t("suppliers.fields.name"),
                                constants.STRING_TYPE
                            ),
                        ],
                        fetcher: (params) => apiClient.catalog.supplier.getSuppliers({
                            ...params,
                            sort: "name",
                            sort_direction: "asc",
                        })
                    },
                    (value) => {
                        return {
                            id: value.id,
                            label: value.name,
                            entity: value,
                        }
                    }
                ),
            ),
            createAsyncSelectFormField(
                "remote_product_provider.id",
                t("catalog.products.fields.remote_product_provider"),
                (params) => remoteProductProvidersFetcher(cache, apiClient, params).then((providers) => (
                    [
                        createFormFieldOption("", t("catalog.products.constants.no_supplier")),
                        ...buildOptions(providers, t, "id", "alias", false)
                    ]
                )),
            ),
        ]),
        createFormFieldset(t("catalog.products.form.fieldset_additional_information"), [
            createFormField(
                createSelectFormField(
                    "lower_price_criteria",
                    t("catalog.products.fields.lower_price_criteria.name"),
                    [
                        createFormFieldOption("", t("catalog.products.constants.unset")),
                        ...Object.entries(lowerPriceCriteriaMapping(t))
                            .map(([key, value]) => createFormFieldOption(key, value))
                    ]
                ),
                false,
                ""
            ),
            {
                ...createFormField(
                    createField(
                        "stop_sales",
                        t("catalog.products.fields.stop_sales"),
                        constants.NUMERIC_TYPE
                    ),
                    true,
                    0
                ),
                max_width: "100%"
            },
            createFormField(
                createField(
                    "availability_time_limit",
                    t("catalog.products.fields.availability_time_limit"),
                    constants.TIME_TYPE
                ),
                false,
                ""
            ),
            createFormField(
                createSelectFormField(
                    "availability_type",
                    t("catalog.products.fields.availability_type.name"),
                    [
                        ...Object.entries(availabilityTypeMapping(t)).map(([key, value]) => createFormFieldOption(key, value))
                    ],
                ),
                true,
                ""
            )
        ]),
        createFormFieldset(t("catalog.products.form.fieldset_options"), [
            createFormFieldGroup([
                createFormField(
                    createField(
                        "with_confirmation",
                        t("catalog.products.fields.requires_confirmation"),
                        constants.BOOLEAN_TYPE
                    ),
                    false,
                    false
                ),
                createFormField(
                    createField(
                        "no_notification_to_reservations",
                        t("catalog.products.fields.no_notification_to_reservations"),
                        constants.BOOLEAN_TYPE
                    ),
                    false,
                    "false"
                ),
                createFormField(
                    createField(
                        "in_inbox",
                        t("catalog.products.fields.in_inbox"),
                        constants.BOOLEAN_TYPE
                    ),
                    false,
                    false
                ),
                createFormField(
                    createField(
                        "with_phone",
                        t("catalog.products.fields.requires_mobile"),
                        constants.BOOLEAN_TYPE
                    ),
                    false,
                    false
                ),
                createFormField(
                    createField(
                        "with_sms_notifications",
                        t("catalog.products.fields.sms_notifications"),
                        constants.BOOLEAN_TYPE
                    ),
                    false,
                    false
                ),
                createFormField(
                    createField(
                        "with_participants",
                        t("catalog.products.fields.with_participants"),
                        constants.BOOLEAN_TYPE
                    ),
                    false,
                    false
                ),
            ]),
            createFormField(
                createSelectFormField(
                    "validation_type",
                    t("catalog.products.fields.validation_type.name"),
                    Object.entries(validationTypeMapping(t))
                        .map(([key, value]) => ({ value: key, label: value }))
                ),
                true,
                "full"
            ),
            createField(
                "reservations_email",
                t("catalog.products.fields.reservations_email"),
                constants.EMAIL_TYPE
            )
        ]),
        createFormFieldset(t("catalog.products.form.fieldset_meeting_point"), [
            createFormField(
                createField(
                    "meeting_point_name",
                    t("catalog.products.fields.name"),
                    constants.STRING_TYPE
                )
            ),
            createFormFieldGroup([
                createFormField(
                    createField(
                        "meeting_point_lat",
                        t("catalog.products.fields.meeting_point_lat"),
                        constants.STRING_TYPE
                    ),
                    false
                ),
                createFormField(
                    createField(
                        "meeting_point_lon",
                        t("catalog.products.fields.meeting_point_lon"),
                        constants.STRING_TYPE
                    ),
                    false
                )
            ])
        ]),
        createFormFieldset(
            t("catalog.products.form.fieldset_accountancy"),
            [
                createAsyncSelectFormField(
                    "accountancy_config.id",
                    t("catalog.products.fields.accountancy_config"),
                    () => entitySchemaKeyItemsFetcher("products_schema", "product_account_names", cache, apiClient, t, false),
                    false,
                    () => user.corporate_account.enterprise.id === 1 // Filter available only for Volcano
                ),
                createFormField(
                    createAsyncSelectFormField(
                        "tax.id",
                        t('catalog.products.fields.tax'),
                        () => {
                            return apiClient.enterprise
                                .getTaxes(user.corporate_account.enterprise.id)
                                .then((taxes) => {
                                    return buildOptions(Object.values(taxes), t, "id", "name", false, false, false)
                                }
                                )
                        },
                        false,
                    ),
                    true,
                )
            ],
        ),
    ]

    return (
        <>
            <AsyncEntityFormPage
                fetcher={fetcher}
                title={
                    createCallbackField(
                        "name",
                        t("catalog.products.form.add_title"),
                        constants.STRING_TYPE,
                        (entity, values) => !entity ? t("catalog.products.form.add_title") : t("catalog.products.form.edit_title", { name: values.name })
                    )
                }
                onSave={onSaveHandler}
                formContent={formContent}
            />
        </>

    )
}

export default ProductFormPage