import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useCache } from "../../../context/cache.context"
import { getTableActions } from "./BookingsCollection.actions"
import { getTableColumns } from "./BookingsCollection.columns"
import { getTableFilter, processFilter } from './BookingsCollection.filter';
import { getTableExport } from "./BookingsCollection.export"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"

const BookingsCollectionPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { i18n, t } = useTranslation("vbms")
    const cache = useCache()
    const volcanoAuth = useVolcanoAuth()

    const actions = []

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {
        selectionMode: "multiple",
        columns: getTableColumns(t, volcanoAuth.user, i18n),
        actions: getTableActions(volcanoAuth.user, apiClient, t),
        fetcher: (params) => apiClient.booking.getBookings(processFilter(filterConfig, params))        
    }

    return (
        <CollectionPage
            title={t("bookings.title")}
            subtitle={t("bookings.subtitle")}
            actions={actions}
            filterConfig={filterConfig}
            table={table}
            onExport={getTableExport(apiClient, t, volcanoAuth.user, filterConfig)}
        />
    )
}

export default BookingsCollectionPage
