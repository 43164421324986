import BooleanFieldValue from "./BooleanFieldValue"
import ListFieldValue from "./ListFieldValue"
import TreeViewFieldValue from "./TreeViewFieldValue"
import DateTimeFieldValue from "./DateTimeFieldValue"
import NumericFieldValue from "./NumericFieldValue"
import CurrencyFieldValue from "./CurrencyFieldValue"
import MappedFieldValue from "./MappedFieldValue"
import StringFieldValue from "./StringFieldValue"
import HtmlFieldValue from "./HtmlFieldValue"
import PickupFieldValue from "./PickupFieldValue"
import StringFormField from "../form/StringFormField"
import BooleanFormField from "../form/BooleanFormField"
import SelectFormField from "../form/SelectFormField"
import AsyncSelectFormField from "../form/AsyncSelectFormField"
import PasswordFormField from "../form/PasswordFormField"
import DateFormField from "../form/DateFormField"
import PhoneFormField from "../form/PhoneFormField"
import ActivityPickupFormField from "../Volcano/ActivityPickupSelector/ActivityPickupFormField"
import AutocompleteFormField from "../form/AutocompleteFormField"
import NumericFormField from "../form/NumericFormField"
import ContactDetailsFieldValue from "./ContactDetailsFieldValue"
import AddressFormField from "../form/AddressFormField"
import DependentFormField from "../form/DependentFormField"
import ValueSelectorDialogFormField from "../form/ValuseSelectorDialogFormField"
import ContactFormField from "../form/ContactFormField"
import ContactFieldValue from "./ContactFieldValue"
import CurrencyFormField from "../form/CurrencyFormField"
import DateTimeFormField from "../form/DateTimeFormField"
import CheckBoxFormField from "../form/CheckBoxFormField"
import TreeViewFormField from "../form/TreeViewFormField"
import AsyncTreeViewFormField from "../form/AsyncTreeViewFormField"
import PercentageFieldValue from "./PercentageFieldValue"
import HtmlFormField from "../form/HtmlFormField"
import EditableListFormField from "../form/EditableListFormField"
import AsyncEditableListFormField from "../form/AsyncEditableListFormField"
import WeekdaysFieldValue from "./WeekdaysFieldValue"
import WeekdaysFormField from "../form/WeekdaysFormField"
import RangeDatesFormField from "../form/RangeDatesFormField"
import TimeFormField from "../form/TimeFormField"
import DynamicValuesListFormField from "../form/DynamicValuesListFormField"
import HiddenFormField from "../form/HiddenFormField"

const TEXT_TYPE = "text"
const STRING_TYPE = "string"
const AGG_TYPE = "aggregate"
const HTML_TYPE = "html"
const EMAIL_TYPE = "email"
const PHONE_TYPE = "phone"
const SELECT_TYPE = "select"
const ASYNC_SELECT_TYPE = "async_select"
const EDITABLE_LIST_TYPE = "editable_list"
const ASYNC_EDITABLE_LIST_TYPE = "async_editable_list"
const DATE_TYPE = "date"
const DATETIME_TYPE = "datetime"
const TIME_TYPE = "time"
const BOOLEAN_TYPE = "boolean"
const CURRENCY_TYPE = "currency"
const NUMERIC_TYPE = "numeric"
const PERCENTAGE_TYPE = "percentage"
const CHECKBOX_TYPE = "checkbox"
const ARRAY_TYPE = "array"
const TREEVIEW_TYPE = "treeview"
const ASYNC_TREEVIEW_TYPE = "async_treeview"
const DOWNLOAD_TYPE = "download"
const DEPENDENT_TYPE = "dependent"
const AUTOCOMPLETE_TYPE = "autocomplete"
const PASSWORD_TYPE = "password"
const VAT_NUMBER_TYPE = "nif"
const PICKUP_TYPE = "pickup"
const ADDRESS_TYPE = "address"
const VALUE_SELECTOR_DIALOG_TYPE = "value_selector_dialog"
const CONTACT_TYPE = "contact"
const WEEKDAYS_TYPE = "weekdays"
const RANGE_DATES_TYPE = "range_dates"
const HIDDEN_TYPE = "hidden"
const DYNAMIC_VALUES_LIST_TYPE = "dynamic_values_list"

const DEFAULT_CURRENCY = "EUR"

const FACET_TYPE_FIELDSET = "fieldset"
const FACET_TYPE_FIELD_GROUP = "fieldgroup"
const FACET_TYPE_ARRAY = "fieldarray"

const FIELD_TYPE_COMPONENT_MAPPING = {
  [TEXT_TYPE]: StringFieldValue,
  [STRING_TYPE]: StringFieldValue,
  [HTML_TYPE]: HtmlFieldValue,
  [EMAIL_TYPE]: StringFieldValue,
  [PHONE_TYPE]: StringFieldValue,
  [SELECT_TYPE]: MappedFieldValue,
  [DATE_TYPE]: DateTimeFieldValue,
  [DATETIME_TYPE]: DateTimeFieldValue,
  [TIME_TYPE]: DateTimeFieldValue,
  [BOOLEAN_TYPE]: BooleanFieldValue,
  [CURRENCY_TYPE]: CurrencyFieldValue,
  [NUMERIC_TYPE]: NumericFieldValue,
  [PERCENTAGE_TYPE]: PercentageFieldValue,
  [ARRAY_TYPE]: ListFieldValue,
  [TREEVIEW_TYPE]: TreeViewFieldValue,
  [PICKUP_TYPE]: PickupFieldValue,
  [ADDRESS_TYPE]: ContactDetailsFieldValue,
  [CONTACT_TYPE]: ContactFieldValue,
  [WEEKDAYS_TYPE]: WeekdaysFieldValue
};

const FORM_FIELD_TYPE_COMPONENT_MAPPING = {
  [TEXT_TYPE]: StringFormField,
  [STRING_TYPE]: StringFormField,
  [EMAIL_TYPE]: StringFormField,
  [PHONE_TYPE]: PhoneFormField,
  [SELECT_TYPE]: SelectFormField,
  [ASYNC_SELECT_TYPE]: AsyncSelectFormField,
  [EDITABLE_LIST_TYPE]: EditableListFormField,
  [ASYNC_EDITABLE_LIST_TYPE]: AsyncEditableListFormField,
  [DATE_TYPE]: DateFormField,
  [DATETIME_TYPE]: DateTimeFormField,
  [TIME_TYPE]: TimeFormField,
  [BOOLEAN_TYPE]: BooleanFormField,
  [NUMERIC_TYPE]: NumericFormField,
  [PERCENTAGE_TYPE]: NumericFormField,
  [CURRENCY_TYPE]: CurrencyFormField,
  //  [ARRAY_TYPE]: SelectFormField,
  [TREEVIEW_TYPE]: TreeViewFormField,
  [ASYNC_TREEVIEW_TYPE]: AsyncTreeViewFormField,
  [CHECKBOX_TYPE]: CheckBoxFormField,
  [DEPENDENT_TYPE]: DependentFormField,
  [AUTOCOMPLETE_TYPE]: AutocompleteFormField,
  [PASSWORD_TYPE]: PasswordFormField,
  [VAT_NUMBER_TYPE]: StringFormField,
  [PICKUP_TYPE]: ActivityPickupFormField,
  [ADDRESS_TYPE]: AddressFormField,
  [VALUE_SELECTOR_DIALOG_TYPE]: ValueSelectorDialogFormField,
  [CONTACT_TYPE]: ContactFormField,
  [HTML_TYPE]: HtmlFormField,
  [WEEKDAYS_TYPE]: WeekdaysFormField,
  [RANGE_DATES_TYPE]: RangeDatesFormField,
  [HIDDEN_TYPE]: HiddenFormField,
  [DYNAMIC_VALUES_LIST_TYPE]: DynamicValuesListFormField
};

export default {
  FIELD_TYPE_COMPONENT_MAPPING,
  FORM_FIELD_TYPE_COMPONENT_MAPPING,
  TEXT_TYPE,
  STRING_TYPE,
  AGG_TYPE,
  HTML_TYPE,
  EMAIL_TYPE,
  PHONE_TYPE,
  SELECT_TYPE,
  ASYNC_SELECT_TYPE,
  SORTABLE_LIST_TYPE: EDITABLE_LIST_TYPE,
  ASYNC_SORTABLE_LIST_TYPE: ASYNC_EDITABLE_LIST_TYPE,
  DATE_TYPE,
  DATETIME_TYPE,
  TIME_TYPE,
  BOOLEAN_TYPE,
  CURRENCY_TYPE,
  CHECKBOX_TYPE,
  NUMERIC_TYPE,
  PERCENTAGE_TYPE,
  DEPENDENT_TYPE,
  ARRAY_TYPE,
  TREEVIEW_TYPE,
  ASYNC_TREEVIEW_TYPE,
  DOWNLOAD_TYPE,
  DEFAULT_CURRENCY,
  PASSWORD_TYPE,
  VAT_NUMBER_TYPE,
  PICKUP_TYPE,
  ADDRESS_TYPE,
  FACET_TYPE_FIELDSET,
  FACET_TYPE_FIELD_GROUP,
  FACET_TYPE_ARRAY,
  VALUE_SELECTOR_DIALOG_TYPE,
  WEEKDAYS_TYPE,
  RANGE_DATES_TYPE,
  HIDDEN_TYPE,
  DYNAMIC_VALUES_LIST_TYPE
  //CONTACT_TYPE
};
