import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import BookingRates from "./components/BookingRates"
import BasePage from "../../../layout/templates/BasePage"
import { Fragment, useCallback, useEffect, useState } from "react"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import { getRateName } from "./components/BookingRates.functions"
import { filterAvailableActions } from "../../../components/action/utils"
import BookingActionRequestWithRefundCost from "../components/BookingActionRequestWithRefundCost"
import { dateObject, objectsEqual } from "../../../lib/utils"
import { CUSTOMER_TYPE_IDS, TELEFERICO_PRODUCTS_AGENCIAS } from '../BookingsCollection/utils';
import userPermissions from "../../../lib/user-permissions"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"

const exchangeRateFetcher = (apiClient, order) => {
    if (order.currency === order.main_currency) {
        return Promise.resolve({ processed_rate: 1 })
    }

    // return apiClient.enterprise.getCurrency(order.enterprise.id, order.currency).then((currency) => {
    //     return currency.exchange_rate
    // })
    return Promise.resolve({ processed_rate: order.currency_exchange_rate })
}

const BookingRatesFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate()
    const [entity, setEntity] = useState()
    const [exchangeRate, setExchangeRate] = useState()
    const [selection, setSelection] = useState()
    const volcanoAuth = useVolcanoAuth()

    const ratesFetcher = useCallback(() => {
        return apiClient.catalog.product
            .getProductRatesForDate(
                entity.product.id,
                dateObject(entity.order.created),
                dateObject(entity.booking_date),
                _isEmpty(entity.order.collaborator) ? null : entity.order.collaborator.crm_intermediary_id,
                entity.order.currency
            )
            .then((result) => result.map((rate) => ({
                ...rate,
                name: getRateName(rate),
                pvp: rate.main_currency.retail_price.value * exchangeRate,
                neto: rate.main_currency.net_price.value * exchangeRate,
                qty_restrictions: {
                    max: rate.qty_restrictions.max,
                    min: volcanoAuth.user.hasPermission(userPermissions.PERM_BOOKINGS_CHANGE_PAX_UNDER_PRODUCT_MIN) ? 0 : rate.qty_restrictions.min
                }
            })))
    }, [apiClient, entity, exchangeRate])

    const handleOnChange = (values) => {
        if (!objectsEqual(selection, values)) {
            setSelection(values)
        }
    }

    const actions = [
        {
            id: "save",
            title: t("common.save"),
            withDialog: {
                title: t("bookings.rates_edit_form.actions.save.edit_cost.modal_title"),
                content: ({ onAction, onCancel }) =>
                    <BookingActionRequestWithRefundCost
                        selection={selection}
                        allowEditCost={entity && entity.hasAction("rates_change_request", "set_refund_cost")}
                        messages={{
                            conditions_text: _get(entity, "_links.rates_change_request.data.confirmation_conditions_message"),
                            confirm_text_not_allowed: "bookings.rates_edit_form.actions.save.edit_cost.confirm_text_not_allowed"
                        }}
                        currency={entity.order.currency}
                        mainCurrency={entity.order.main_currency}
                        exchangeRate={entity.order.currency_exchange_rate}
                        onAction={onAction}
                        onCancel={onCancel}
                    />,
                condition: () =>
                    selection && (selection.qty_changed || selection.amount_changed) && !selection.price_increased &&
                    ((entity.refund_pax_cost === null && entity.hasAction("rates_change_request", "set_refund_cost")) || selection.refund_cost > 0)
            },
            showLoading: true,
            onExecute: (result, progressHandler, onSuccess, onError) => {
                result = result ? result : selection
                if (result !== undefined) {
                    if (!_get(result, "participants_valid", true)) {
                        const error = t("bookings.rates_edit_form.participants_error", {
                            locator: entity.locator,
                        })

                        onError(error)
                        throw new Error(error)
                    } else if (!_get(result, "amounts_valid", true)) {
                        const error = t("bookings.rates_edit_form.amounts_error", {
                            locator: entity.locator,
                        })

                        onError(error)
                        throw new Error(error)
                    } else if (result.qty_changed || result.amount_changed) {
                        return apiClient.booking.changeRates(entityId, result)
                            .then((booking) => {
                                onSuccess(
                                    t((booking.state === "refund_requested") ?
                                        "bookings.rates_edit_form.actions.save.confirm_with_refund" :
                                        "bookings.rates_edit_form.actions.save.confirm",
                                        {
                                            locator: entity.locator,
                                        }
                                    )
                                )

                                navigate("/orders/bookings/" + entityId)

                                return booking
                            })
                            .catch((error) => {
                                onError(
                                    t("bookings.rates_edit_form.actions.save.error", {
                                        locator: entity.locator,
                                    })
                                )

                                throw error
                            })
                    } else if (entity.product.with_participants) {
                        if (result.participants_changed) {
                            return apiClient.booking.changeParticipants(entityId, { participants: result.participants_changed })
                                .then((booking) => {
                                    onSuccess(
                                        t("bookings.rates_edit_form.actions.save.confirm_participants",
                                            {
                                                locator: entity.locator,
                                            }
                                        )
                                    )

                                    navigate("/orders/bookings/" + entityId)

                                    return booking
                                })
                                .catch((error) => {
                                    onError(
                                        t("bookings.rates_edit_form.actions.save.error", {
                                            locator: entity.locator,
                                        })
                                    )

                                    throw error
                                })
                        }
                    } else {
                        onError(
                            t("bookings.rates_edit_form.no_changes_error", {
                                locator: entity.locator,
                            })
                        )
                    }
                }
            },
            condition: () => {
                return !_isEmpty(selection) &&
                    (
                        selection.booking_rates
                            .filter((item) => entity.product.id === TELEFERICO_PRODUCTS_AGENCIAS.SUDIBA_BAJADA_AGENCIAS ?
                                !item.customer_types.includes(CUSTOMER_TYPE_IDS.GUIDES) :
                                item
                            )
                            .reduce((acc, rate) => acc + rate.qty, 0) >= entity.product.qty_restrictions.min
                    ) &&
                    (
                        !(entity.product?.container_configuration?.customer_types_restrictions) ||
                        entity.product?.container_configuration?.customer_types_restrictions.length === 0 ||
                        (
                            entity.product.container_configuration.customer_types_restrictions.every(ctId => {
                                return selection.booking_rates.some(rate => rate.customer_types.includes(ctId) && rate.qty > 0)
                            })
                        )
                    ) &&
                    (!selection.price_increased || entity.hasAction("rates_change_request", "rates_increase_booking_total_amount"))
            }
        },
        {
            id: "cancel",
            title: t("common.cancel"),
            onExecute: () => {
                navigate("/orders/bookings/" + entityId)
            },
        },
    ]

    useEffect(() => {
        apiClient.booking.getBooking(entityId, { view_mode: "full" }).then((booking) => {
            if (booking.hasAction("rates_change_request")) {
                apiClient.catalog.product.getProduct(booking.product.id, true).then(
                    (product) => {
                        booking.product = product
                        booking.product.qty_restrictions.min = volcanoAuth.user.hasPermission(userPermissions.PERM_BOOKINGS_CHANGE_PAX_UNDER_PRODUCT_MIN) ? 0 : booking.product.qty_restrictions.min
                        if (booking.product?.container_configuration?.qty_restrictions) {
                            booking.product.container_configuration.qty_restrictions.min = volcanoAuth.user.hasPermission(userPermissions.PERM_BOOKINGS_CHANGE_PAX_UNDER_PRODUCT_MIN) ? 0 : booking.product.container_configuration.qty_restrictions.min
                        }

                        booking.product_rates = booking.product_rates.map(
                            (rate) => (
                                {
                                    ...rate,
                                    qty_restrictions: {
                                        max: rate.qty_restrictions.max,
                                        min: volcanoAuth.user.hasPermission(userPermissions.PERM_BOOKINGS_CHANGE_PAX_UNDER_PRODUCT_MIN) ? 0 : rate.qty_restrictions.min
                                    }
                                }
                            )
                        )

                        setEntity(booking)
                    }
                )

                exchangeRateFetcher(apiClient, booking.order).then((result) => {
                    setExchangeRate(result.processed_rate)
                })
            } else {
                navigate("/orders/bookings/" + entityId)
            }
        })
    }, [apiClient, entityId, navigate])

    return (
        <Fragment>
            {entity && exchangeRate && <BasePage
                title={t("bookings.rates_edit_form.title", {
                    locator: entity.locator
                })}
                subtitle={`${entity.product.experience.name} / ${entity.product.name}`}
                actions={filterAvailableActions(actions, entity)}
            >
                <BookingRates
                    entity={entity}
                    ratesFetcher={ratesFetcher}
                    currency={entity.order.currency}
                    mainCurrency={entity.order.main_currency}
                    exchangeRate={exchangeRate}
                    costExchangeRate={entity.order.currency_exchange_rate}
                    onChange={handleOnChange}
                />
            </BasePage>}
        </Fragment>
    )
}

export default BookingRatesFormPage