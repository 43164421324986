import constants from "../../../components/entity/constants"
import { createAggField, createAsyncSelectFormField, createBooleanFormField, createDependentFormField, createDialogFormField, createField, createFormField, createFormFieldOption } from '../../../components/helpers/fieldHelper';
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { buildOptions, paymentModesFetcher } from '../../../lib/form-options-fetchers';
import { getActivitiesFilter, getIntermediariesFilter, getDiscountCodesFilter } from "../../../lib/collection-filters";
import userPermissions from "../../../lib/user-permissions";
import {format as formatDate} from 'date-fns';
import _isDate from 'lodash/isDate';
import _set from 'lodash/set';
import _size from 'lodash/size';
import _isArray from 'lodash/isArray';

export const getTableFilter = (apiClient, t, user, cache) => ([
    createAggField(
        "agg_fields",
        "",
        [
            {
                id: "total_pax",
                label: "bookings.aggregate.total_pax",
            }, {
                id: "total_retail_price",
                label: "bookings.aggregate.total_retail_price",
                type: constants.CURRENCY_TYPE
            }, {
                id: "total_net_price",
                label: "bookings.aggregate.total_net_price",
                type: constants.CURRENCY_TYPE,
                condition: () => user.hasPermission(userPermissions.PERM_BOOKINGS_VIEW_NET_PRICE)
            }
        ],
    ),
    createField(
        "locator",
        t("bookings.fields.locator"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createField(
        "experience_name",
        t("bookings.fields.experience"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createField(
        "product_name",
        t("bookings.fields.product"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createField(
        "customer",
        t("bookings.filter.customer_name"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createField(
        "order_date_from",
        t("bookings.filter.order_date_from"),
        constants.DATE_TYPE
    ),
    createField(
        "order_date_to",
        t("bookings.filter.order_date_to"),
        constants.DATE_TYPE
    ),
    createField(
        "booking_date_from",
        t("bookings.filter.booking_date_from"),
        constants.DATE_TYPE,
        null,
        null,
        true
    ),
    createField(
        "booking_date_to",
        t("bookings.filter.booking_date_to"),
        constants.DATE_TYPE,
        null,
        null,
        true
    ),
    getIntermediariesFilter(apiClient, t, user, 'collaborator_id', false),
    createDialogFormField(
        "supplier_id",
        t("bookings.filter.supplier"),
        false,
        {
            filterConfig: [
                createFormField(
                    createField(
                        "name",
                        t("bookings.filter.name"),
                        constants.STRING_TYPE
                    ),
                    false
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("bookings.filter.supplier"),
                    constants.STRING_TYPE
                )
            ],
            fetcher: (params) => apiClient.catalog.supplier.getSuppliers({ ...params })
        },
        (value) => ({
            id: value.id,
            label: value.name,
            entity: value
        }),
        () => user.hasPermission('view-any-supplier')
    ),
    /*createSelectFormField(
        "state",
        t("bookings.filter.state"),
        [createFormFieldOption("", t("table.all"))].concat(
            Object.entries(createBookingStateMapping(t)).map(([value, label]) => ({ value: value, label: label }))
        )
    ),*/
    createBooleanFormField(
        t,
        "confirmed",
        t("bookings.filter.confirmed")
    ),
    createFormField(
        createAsyncSelectFormField(
            "payment_method_id",
            t("bookings.filter.payment_method"),
            () => {
                return paymentModesFetcher(cache, apiClient, t).then((result) => {
                    return result.filter((item) => item.label !== t("bookings.constants.payment_method.paygold") && item.label !== t("bookings.constants.payment_method.oneclick"))
                })
            },
            true,
            () => !user.isIntermediary() && user.hasPermission(userPermissions.PERM_ENTERPRISES_PAYMENT_GATEWAYS_VIEW),
            null,
            false,
            true
        ),
        false,
        []
    ),
    createField(
        "transaction_id",
        t("bookings.filter.transaction_id"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createBooleanFormField(
        t,
        "valid",
        t("bookings.filter.valid"),
        null,
        true,
        1
    ),
    createFormField(
        createAsyncSelectFormField(
            "cancelled",
            t("bookings.filter.cancelled"),
            () => {
                const options = [
                    createFormFieldOption("", t("table.all")),
                    createFormFieldOption(1, t("common.yes")),
                    createFormFieldOption(0, t("common.no")),
                    createFormFieldOption("-1", t("bookings.filter.with_cost")),
                    createFormFieldOption("-2", t("bookings.filter.without_cost")),
                ];
                return options
            },
            false
        ),
        false,
        []
    ),
    createBooleanFormField(
        t,
        "validated",
        t("bookings.filter.validated")
    ),
    createAsyncSelectFormField(
        "currency",
        t("bookings.filter.currencies"),
        () => {
            const enterpriseId = parseInt(_get(user, "corporate_account.enterprise.id", "-1"))
            let result = [
                createFormFieldOption("", t("table.all")),
                createFormFieldOption("main", t("currencies.fields.main")),
                createFormFieldOption("others", t("currencies.fields.others"))
            ]

            return cache.get("enterprises", []).then((enterprises) => {

                if (_isEmpty(enterprises)) {
                    return result
                }

                const enterprise = enterprises.find((enterprise) => enterprise.id === enterpriseId)
                if (enterprise.currencies) {
                    result = [...result, ...buildOptions(enterprise.currencies.available, t, "id", "id", false)]
                }

                return result
            })
        },
        false,
        () => user.corporate_account.enterprise.id === 1 // Filter available only for Volcano
    ),
    createFormField(
        createAsyncSelectFormField(
            "office_id",
            t("bookings.filter.office"),
            () => {
                const options = [createFormFieldOption("", t("table.all"))]
                return cache.get("offices", []).then((items) => {
                    return options.concat(items.map((item) => ({ value: item.collaborator_office_id, label: item.name })))
                })
            },
            false,
            () => user.isIntermediary() && user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_OFFICE_VIEW)
        ),
        false,
        user.hasOwnProperty('crm_intermediary_office') ? user.crm_intermediary_office.collaborator_office_id : null,
    ),
    createAsyncSelectFormField(
        "salesman_id",
        t("bookings.filter.salesman"),
        () => {
            const options = [createFormFieldOption("", t("table.all"))]
            return cache.get("salesmen", []).then((items) => {
                return options.concat(items.map((item) => ({ value: item.id, label: item.name })))
            })
        },
        false,
        () => cache.has("salesmen")
    ),
    getDiscountCodesFilter(apiClient, t, user),
    getActivitiesFilter(apiClient, t, user),
    createField(
        "notes",
        t("bookings.fields.order.customer.notes"),
        constants.STRING_TYPE,
        null,
        () => !user.isIntermediary()
    ),
    createDependentFormField(
        "activity_pickup_id",
        t("bookings.filter.activity_pickup"),
        constants.SELECT_TYPE,
        (values) => {
            const activityPickups = _get(values, "activity_id[0].entity.activity_pickups", [])

            if (activityPickups.length === 0) {
                return []
            }

            const options = [createFormFieldOption("", t("table.all"))]
            return options.concat(activityPickups.map((item) => ({ value: item.id, label: item.name })))
        },
        () => user.hasPermission('view-any-activity')
    ),
    createFormField(
        createAsyncSelectFormField(
            "internal_tag_id",
            t("bookings.filter.internal_tags"),
            () => {
                return cache.get("internal_tags", []).then(
                    (items) => buildOptions(items, t, "id", "name", false)
                )
            },
            true
        ),
        false,
        []
    )
])

export const processFilter = (filterConfig, params) => {
    filterConfig
        .filter(field => field.type === constants.DATE_TYPE)
        .forEach(field => {
            const date = _get(params, field.id)
            if (_isDate(date)) {
                _set(params, field.id, formatDate(date, "yyyy-MM-dd"))
            }
        })

    filterConfig
        .filter(field => (field.type === constants.STRING_TYPE ||
            field.type === constants.TEXT_TYPE))
        .forEach(field => {
            if (_get(params, field.id) !== '') {
                const param = _get(params, field.id)
                _set(params, field.id, param.trim())
            }
        })

    let collaboratorFilter = [];
    if (_get(params, "collaborator_id", null)) {
        if (_isArray(_get(params, "collaborator_id", null))) {
            _get(params, "collaborator_id", null).forEach(col => {
                collaboratorFilter.push(col.id)
            });
        } else {
            collaboratorFilter.push(_get(params, "collaborator_id", null));
        }
    }
    if (_get(params, "payment_method_id", null) === '') {
        _set(params, "payment_method_id", [])
    }
    if (_get(params, "discount_code_id", null) === '') {
        _set(params, "discount_code_id", [])
    }
    if (_get(params, "activity_id", null) === '') {
        _set(params, "activity_id", [])
    }
    if (_get(params, "supplier_id", null) === '') {
        _set(params, "supplier_id", [])
    }

    const cancelledValue = _get(params, "cancelled", '0');

    if (cancelledValue === '-1') {
        _set(params, "with_cost", 1)
    } else if (cancelledValue === '-2') {
        _set(params, "with_cost", 0)
    }

    if ( _size(cancelledValue) !== 0 && cancelledValue !== '0') {
        _set(params, "cancelled", 1);
    }

    const queryFilter = {
        ...params,
        activity_id: _get(params, "activity_id[0].id", null),
        collaborator_id: collaboratorFilter,
        supplier_id: _get(params, "supplier_id[0].id", null),
        discount_code_id: _get(params, "discount_code_id[0].id", null),
        required_actions: ["set_no_show", "set_exchange_date"].join("|"),
    }
    
    return queryFilter
}