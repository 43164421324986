import availableRoutes from '../../../routes/availableRoutes'
import NotificationTemplateAddProduct from "../components/NotificationTemplateAddProduct"

export const getActions = (user, apiClient, entityId, t, navigate) => [
    {
        id: "edit",
        title: t("common.edit"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.notification_templates_edit.path.replace(":entityId", entityId))
        },
        condition: () => user.hasPermission("administer-any-notification-template")
    },
    {
        id: "add_product",
        title: t("notification_templates.actions.add_product.button"),
        reloadOnSuccess: true,
        showLoading: true,
        withDialog: {
            title: t("notification_templates.actions.add_product.modal_title"),
            content: NotificationTemplateAddProduct,
            maxWidth: "lg",
        },
        onExecute: (data, progressHandler, onSuccess, onError) => {
            const products = data.map((product) => ({ id: product.id }))

            return apiClient.notifications.addProductToNotificationTemplate(entityId, products)
                .then((data) => {
                    onSuccess(t("notification_templates.actions.add_product.confirm"))
                    navigate(availableRoutes.notification_templates_view.path.replace(":entityId", entityId))
                })
                .catch((error) => {
                    onError(t("notification_templates.actions.add_product.error"))
                    throw error
                })
        },
        condition: () => user.hasPermission("administer-any-notification-template")
    },
    {
        id: "create_notification",
        title: t("notification_templates.actions.create_notification.button"),
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.createNotificationFromTemplate(entityId)
                .then((notification) => {
                    onSuccess(t("notification_templates.actions.create_notification.confirm"))
                    navigate(availableRoutes.notifications_edit.path.replace(":entityId", notification.id))
                })
                .catch((error) => {
                    onError(t("notification_templates.actions.create_notification.error"))
                    throw error
                })
        },
        condition: () => user.hasPermission("administer-any-notification")
    },
    {
        id: "copy",
        title: t("notification_templates.actions.copy.button"),
        onExecute: (template, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.copyNotificationTemplate(entityId)
                .then((result) => {
                    onSuccess(t("notification_templates.actions.copy.confirm", {
                        name: result.name,
                    }))
                    navigate(availableRoutes.notification_templates_view.path.replace(":entityId", result.id))
                })
                .catch((error) => {
                    onError(
                        t("notification_templates.actions.copy.error", {
                            name: template.name,
                        })
                    )

                    throw error
                })
        },
        condition: () => user.hasPermission("administer-any-notification-template")
    },
    {
        id: "delete",
        title: t("common.delete"),
        confirmDialog: {
            title: t("notification_templates.actions.delete.modal_title"),
            content: (selection) =>
                t("notification_templates.actions.delete.modal_content", {
                    name: selection.name,
                }),
        },
        showLoading: true,
        onExecute: (template, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.deleteNotificationTemplate(entityId)
                .then((result) => {
                    onSuccess(
                        t("notification_templates.actions.delete.confirm", {
                            name: template.name,
                        })
                    )
                    navigate(availableRoutes.notification_templates.path)
                })
                .catch((error) => {
                    onError(
                        t("notification_templates.actions.delete.error", {
                            name: template.name,
                        })
                    )

                    throw error
                })
        },
        condition: (template) => (user.hasPermission("administer-any-notification-template") && !template.system_template)
    }
]
