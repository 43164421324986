import { createTabBarItem } from '../../../components/helpers/fieldHelper';
import _isEmpty from "lodash/isEmpty"
import NotificationProducts from '../../../components/Volcano/Notifications/NotificationProducts';
import { Delete } from '@mui/icons-material';
import availableRoutes from '../../../routes/availableRoutes';
import NotificationTemplateRecipients from './components/tabs/NotificationTemplateRecipients/NotificationTemplateRecipients';
import { Box } from '@mui/material';

const getActions = (entity, t, apiClient, volcanoAuth, navigate) => {
    let actions = [
        {
            id: "delete",
            title: t("common.delete"),
            scope: "row",
            icon: Delete,
            showLoading: true,
            onExecute: (product, progressHandler, onSuccess, onError) => {
                return apiClient.notifications.deleteProductFromNotificationTemplate(entity.id, product.id)
                    .then((result) => {
                        onSuccess(t("notification_templates.actions.delete_product.confirm"))
                        navigate(availableRoutes.notification_templates_view.path.replace(":entityId", entity.id))
                    })
                    .catch((error) => {
                        onError(t("notification_templates.actions.delete_product.error"))
                        throw error
                    })
            },
            condition: (product) => volcanoAuth.user.hasPermission("administer-any-notification-template")
        },
    ]
    return actions
}

export const getTabNavigationItems = (legend, t, apiClient, volcanoAuth, navigate) => {

    return ([
        createTabBarItem(
            t("notification_templates.view.navbar.products"),
            ({ entity }) => {
                return (
                    <NotificationProducts
                        products={entity.products}
                        actions={getActions(entity, t, apiClient, volcanoAuth, navigate)}
                    />
                )
            }
            ,
            null,
            (entity) => !_isEmpty(entity.products)
        ),
        createTabBarItem(
            t("notification_templates.view.navbar.recipients"),
            ({ entity }) => {
                return (<NotificationTemplateRecipients
                    entityId={entity.id}
                    recipients={entity.recipients}
                    edit={volcanoAuth.user.hasPermission("administer-any-notification-template")}
                />
                )
            },
            null,
        ),
        createTabBarItem(
            t("notification_templates.view.navbar.configuration"),
            () =>
                <Box>{t("notification_templates.view.no_configuration")}</Box>
            ,
            null,
            (entity) => _isEmpty(entity.products) && _isEmpty(entity.recipients)
        )
    ])
};