import {getActions as getRowActions} from "../../../Content/Tags/TagPage/TagPage.actions";
import userPermissions from "../../../../lib/user-permissions";
import ZoneForm from "../ZoneForm";


export const getActions = (apiClient, t, navigate, zone) => [
    {
        id: "add_zone",
        title: t("common.create"),
        withDialog: {
            title: t("zones.actions.add.modal_title"),
            content: ZoneForm
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.activityManager.zone.addZone(data)
                .then(() => {
                    onSuccess(
                        t("zones.actions.add.confirm", {
                            name: data.name,
                        })
                    )
                })
                .catch((error) => {
                    onError(
                        t("zones.actions.add.error", {
                            name: data.name,
                        })
                    )
                })
        },
        condition: () => zone.hasAnyPermission([
            userPermissions.PERM_ZONES_ADMINISTER,
            userPermissions.PERM_ZONES_ADMINISTER_OWN
        ])
    }
]


export const getTableActions = (...props) => getRowActions(...props)