import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../../context/VolcanoAuthContext"
import { useCache } from "../../../../context/cache.context"
import { getTableFilter } from "./TagsCollection.filter"
import { getTableColumns } from "./TagsCollection.columns"
import { getActions} from "./TagsCollection.action"
import CollectionPage from "../../../../layout/templates/CollectionPage/CollectionPage"


const TagsCollectionPage = () => {
    const { t } = useTranslation("vbms")
    const cache = useCache()
    const navigate = useNavigate();
    const volcanoAuth = useVolcanoAuth()
    const { apiClient } = useVolcanoApiClient()

    const filterConfig = getTableFilter(apiClient, t, volcanoAuth.user, cache)

    const table = {        
        columns: getTableColumns(t),
        fetcher: (params) => apiClient.content.tag.getTags(params),
    }

    return (
        <CollectionPage
            title={t("tags.title")}
            subtitle={t("tags.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            filterConfig={filterConfig}
            table={table}
        />
    )
}

export default TagsCollectionPage
