import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider"
import AsyncEntityProductRatesTable from "../../../../Catalog/components/ProductsTable"

const IntermediaryProducts = ({ entity }) => {
    const { apiClient } = useVolcanoApiClient()

    if (!entity) {
        return null;
    }

    const fetcher = () => 
        apiClient.intermediary.getIntermediaryProducts(entity.id, {state: "all", limit: 1000})
        .then((collection) => {
            return collection.getItems().map((product) => {
                product.container = {
                    id: entity.id,
                    name: entity.name,
                }

                return product
            })
    }) 

    const actionHandlers = {
        add: (containerId, data) => apiClient.intermediary.editIntermediaryProducts(containerId, data),
        edit: (containerId, productId, data) => apiClient.intermediary.editIntermediaryProduct(containerId, productId, data),
        sync: (productRatesPublicationId, containerId, productId) => apiClient.catalog.productRatesPublication.editProductRatePublicationProducts(productRatesPublicationId, containerId, "intermediary", {add: [{id: productId}]}),
        delete: (containerId, productId) => apiClient.intermediary.deleteIntermediaryProduct(containerId, productId),
    }

    return <AsyncEntityProductRatesTable entity={entity} fetcher={fetcher} actionHandlers={actionHandlers} hideCustomConditions={false} />
}

export default IntermediaryProducts