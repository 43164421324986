import { useTranslation } from "react-i18next"
import _set from 'lodash/set'
import AsyncEntityForm from "../../../../../../components/form/AsyncEntityForm"
import { createEditableListFormField, createField, createFormFieldset } from "../../../../../../components/helpers/fieldHelper"
import ValueSelectorDialog from "../../../../../../components/ValueSelectorDialog/ValueSelectorDialog"
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider"
import constants from "../../../../../../components/entity/constants"
import apiConstants from "../../../../../../lib/api-constants"

const ProductAddTag = ({ selection, onAction, internalTag }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")

    const selectedTags = internalTag ? selection.internal_tags || [] : selection.tags || []

    const table = {
        filterConfig: [
            createField(
                "name",
                t("tags.fields.name"),
                constants.TEXT_TYPE
            )
        ],
        columns: [
            createField(
                "name",
                t("tags.fields.name"),
                constants.TEXT_TYPE
            )
        ],
        fetcher: (params) => {
            _set(params, "is_admin", internalTag ? 1 : 0);
            _set(params, "not_ids", selectedTags.map((tag) => tag.id))

            return apiClient.content.tag.getTags({
                ...params,
                sort: "name",
                sort_direction: "asc",
            })
        }
    }

    const valueFormatter = (value) => ({
        id: value.id,
        label: value.name,
        entity: value
    })

    const fetcher = () => Promise.resolve({ tags: selectedTags })

    const handleOnConfirm = (value) => {
        onAction({
            tags: value.tags.map((item) => ({
                id: parseInt(item.id),
                name: item.label
            }))
        })
    }

    const i18nPrefix = "catalog.products.actions." + (internalTag ? "add_internal_tag" : "add_tag")

    const formContent = [
        createFormFieldset("", [
            createEditableListFormField(
                "tags",
                null,
                "name",
                t(`${i18nPrefix}.actions.add.modal_title`),
                ({ selected, onConfirm, onCancel }) => <ValueSelectorDialog
                    table={table}
                    selected={selected}
                    valueFormatter={valueFormatter}
                    multipleSelection={true}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />,
                false,
                true
            )
        ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={handleOnConfirm}
            formContent={formContent}
        />
    )
}

export default ProductAddTag 