import Constants from "./constants"
import DataSetWrapper from '../Display/DataSetWrapper';
import constants from './constants';

const ContactFieldValue = (props) => {

    const fields = [
        /*    {
                field: "contact_type.name",
                label: "intermediaries.fields.contact_type",
                type: Constants.STRING_TYPE
            },*/
        {
            field: "name",
            label: "common.fields.contact.name",
            type: Constants.STRING_TYPE
        },
        {
            field: "surname",
            label: "common.fields.contact.surname",
            type: Constants.STRING_TYPE
        },
        {
            field: "notify_closure",
            label: "contact.fields.notify_closure",
            type: Constants.BOOLEAN_TYPE
        },
        {
            field: "restrict_calls",
            label: "contact.fields.restrict_calls",
            type: Constants.BOOLEAN_TYPE
        },
        {
            field: "position",
            label: "contact.fields.title",
            type: Constants.STRING_TYPE
        },
        {
            field: "department",
            label: "contact.fields.department",
            type: Constants.STRING_TYPE
        },
        {
            field: "language",
            label: "intermediaries.fields.language",
            type: constants.STRING_TYPE,
            mapping: props.languagesMapping
        },
        {
            field: "contact_method",
            label: "contact.fields.contact_method",
            type: Constants.STRING_TYPE
        },
        {
            field: "contact_details",
            label: null,
            type: Constants.ADDRESS_TYPE
        },
    ]

    return (
        <DataSetWrapper
            title={props.title}
            data={props.data}
            config={fields}
            variant="inline"
        />
    )
}

export default ContactFieldValue