import CollectionDataTable from "../../Datatable/CollectionDataTable"
import { getProductsFilter } from "../../../lib/collection-filters"
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth} from "../../../context/VolcanoAuthContext";

const ProductsTable = ({ defaultParams, ignoreSearchParams }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()    
    const { user } = useVolcanoAuth()

    const productsFilter = getProductsFilter(apiClient, t, user, false, defaultParams)  

    return (
        <CollectionDataTable
            ignoreSearchParams={ignoreSearchParams}
            fetcher={productsFilter.table.fetcher}
            columns={productsFilter.table.columns}
            filterConfig={productsFilter.table.filterConfig}
        />
    )
}

export default ProductsTable
