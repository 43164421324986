import CachedIcon from '@mui/icons-material/Cached'
import PublishIcon from '@mui/icons-material/Publish'
import SiteFeaturedProductsForm from './components/actions/SiteFeaturedProductsForm'
import availableRoutes from '../../../routes/availableRoutes'

export const getActions = (apiClient, t, user, itemProps = {}) => {
    const { scope, hideIcons } = { ...itemProps }

    return [
        {
            id: "edit",
            title: t("sites.actions.edit.button"),
            onExecute: (site) => {
                const baseUrl = user.settings.disfrutare.base_url
                const loginUrl = user.settings.disfrutare.login_url
                const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

                window.open(url.replace("[URL]", availableRoutes.disfrutare_content_site_edit.path.replace(":entityId", site.id)))
            },
            condition: (site) => site.hasAction("edit")
        },
        {
            id: "translate",
            title: t("sites.actions.translate.button"),
            onExecute: (site) => {
                const baseUrl = user.settings.disfrutare.base_url
                const loginUrl = user.settings.disfrutare.login_url
                const url = (baseUrl + loginUrl).replace("[TOKEN]", localStorage.getItem("jwt"))

                window.open(url.replace("[URL]", availableRoutes.disfrutare_content_site_translate.path.replace(":entityId", site.id)))
            },
            condition: (site) => site.hasAction("edit")
        },
        {
            id: "clear_cache",
            scope: scope,
            icon: hideIcons ? null : CachedIcon,
            title: t("sites.actions.clear_cache.button"),
            showLoading: true,
            onExecute: (site, progressHandler, onSuccess, onError) => {
                return apiClient.content.site.invalidateCache(site.id)
                    .then((result) => {
                        onSuccess(
                            t("sites.actions.clear_cache.confirm", { name: site.name })
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("sites.actions.clear_cache.error", { name: site.name })
                        )
                    })
            },
            condition: (site) => site.hasAction("cache_invalidations"),
        },
        {
            id: "publish",
            scope: scope,
            icon: hideIcons ? null : PublishIcon,
            title: t("sites.actions.publish.button"),
            confirmDialog: {
                title: t("sites.actions.publish.modal_title"),
                content: (site) => t("sites.actions.publish.modal_content", { name: site.name }),
            },
            showLoading: true,
            onExecute: (site, progressHandler, onSuccess, onError) => {
                return apiClient.content.site.publish(site.id)
                    .then((result) => {
                        onSuccess(
                            t("sites.actions.publish.confirm", { name: site.name })
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("sites.actions.publish.error", { name: site.name })
                        )
                    })
            },
            condition: (site) => site.hasAction("publish"),
        },
        {
            id: "set_featured_products",
            scope: scope,
            icon: hideIcons ? null : PublishIcon,
            title: t("sites.actions.set_featured_products.button"),
            withDialog: {
                title: (site) => t("sites.actions.set_featured_products.modal_title", { name: site.name }),
                content: SiteFeaturedProductsForm,
            },            
            showLoading: true,
            reloadOnSuccess: true,
            onExecute: (data, progressHandler, onSuccess, onError) => {
                return apiClient.content.site.setFeaturedProducts(data.site.id, data.featured_products)
                    .then((result) => {
                        onSuccess(
                            t("sites.actions.set_featured_products.confirm", { name: data.site.name })
                        )

                        return result
                    })
                    .catch((error) => {
                        onError(
                            t("sites.actions.set_featured_products.error", { name: data.site.name })
                        )
                    })
            },
            condition: (site) => site.hasAction("set_featured_products"),
        },
    ]
}
