import {getActions as getRowActions} from "../../../Content/Tags/TagPage/TagPage.actions";
import userPermissions from "../../../../lib/user-permissions";
import availableRoutes from "../../../../routes/availableRoutes";


export const getActions = (apiClient, t, navigate, activityConfig) => [
    {
        id: "add_activity",
        title: t("common.create"),
        onExecute: () => {
            navigate(availableRoutes.activity_add.path)
        },
        condition: () => activityConfig.hasAnyPermission([
            userPermissions.PERM_ACTIVITY_CONFIGS_ADMINISTER,
            userPermissions.PERM_ACTIVITY_CONFIGS_ADMINISTER_OWN
        ])
    }
]


export const getTableActions = (...props) => getRowActions(...props)
