import ProductsTable from "../../../../../../components/Volcano/Products/ProductsTable";
import apiConstants from "../../../../../../lib/api-constants"
import _has from 'lodash/has';

const TagProducts = ({ entity }) => {

    if (!entity) {
        return null;
    }

    const paramName = (_has(entity, "sote") && entity.site.is_admin) ? "internal_tag_id" :"tag_id"

    return (
        <ProductsTable 
            ignoreSearchParams={true}
            defaultParams={{ [paramName]: entity.id }} 
        />
    )
}

export default TagProducts