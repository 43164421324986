import _isArray from 'lodash/isArray'
import _isDate from "lodash/isDate"
import _join from "lodash/join"
import _get from "lodash/get"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"
import { parseISO } from "date-fns"
import constants from '../components/entity/constants'

export const downloadFile = (data, filename) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }))
    var link = document.createElement('a')
    link.href = url
    link.download = filename
    link.click()
}

export const objectsEqual = (o1, o2) => {
    if (o1 === undefined || o2 === undefined) {
        return o1 === o2
    }

    return Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => {
            if (_isArray(o1[p]) || _isArray(o2[p])) {
                return arraysEqual(o1[p], o2[p])
            }

            return o1[p] === o2[p]
        })
}

export const arraysEqual = (a1, a2) => {
    return _isArray(a1)
        && _isArray(a2)
        && a1.length === a2.length
        && a1.every((o, idx) => objectsEqual(o, a2[idx]));
}

export const orderItemsByKey = (items, key) => {
    return items.sort((a, b) => {
        if (a[key] < b[key]) {
            return -1
        } else if (a[key] > b[key]) {
            return 1
        } else {
            return 0
        }
    })
}

export const getCurrencyLocale = (locale) => {
    return (locale === "en") ? locale : "de"
}

export const formatDecimal = (i18n, value) => {
    const locale = getCurrencyLocale(i18n.language)

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value)
}

export const formatPrice = (i18n, currency, price, mainCurrency, exchangeRate) => {
    const locale = getCurrencyLocale(i18n.language)

    const priceFormatter = new Intl.NumberFormat(locale, {
        style: constants.CURRENCY_TYPE,
        currency: currency || constants.DEFAULT_CURRENCY,
    })

    if (currency && mainCurrency && currency !== mainCurrency) {
        const mainCurrencyPriceFormatter = new Intl.NumberFormat(locale, {
            style: constants.CURRENCY_TYPE,
            currency: mainCurrency,
        })

        return "" + priceFormatter.format(price) + " (" + mainCurrencyPriceFormatter.format(price / exchangeRate) + ")"

    }

    return priceFormatter.format(price)
}

export const formatEmptyPrice = (i18n, currency, mainCurrency) => {
    if (currency && mainCurrency && currency !== mainCurrency) {
        return "-- " + formatCurrencyCode(i18n, currency) + " (-- " + formatCurrencyCode(i18n, mainCurrency) + ")"
    }

    return "-- " + formatCurrencyCode(i18n, currency)
}

export const formatCurrencyCode = (i18n, currency) => {
    const priceFormatter = new Intl.NumberFormat(getCurrencyLocale(i18n.language), {
        style: constants.CURRENCY_TYPE,
        currency: currency || constants.DEFAULT_CURRENCY,
    })

    return priceFormatter.formatToParts().filter((format) => format.type == 'currency')[0].value
}

export const getLastDays = (days) => {
    const date = new Date()

    return new Date(new Date().setDate(date.getDate() - days))
}

export const getLastYears = (years) => {
    const date = new Date()

    return date.setFullYear(date.getFullYear() - 1)
}

export const trimWithEllipsis = (str, maxLength = 100) => {
    if (str && typeof str === 'string') {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...'
        }

        return str
    }

    return null
}

export const pushFields = (data, columns) => {
    columns.forEach(column => {
        if (column.condition) {
            data.splice(column.index || data.length, 0, column.field)
        }
    })
}

export const getSuggestedForCollaboratorFilename = (title, collaborator, normalize = false) => {
    let suggestedFilename = title
    if ((collaborator) && (collaborator.fact_razon_social || collaborator.name)) {
        let collaboratorName = collaborator.fact_razon_social ?? collaborator.name
        if (collaboratorName.length > 20) {
            collaboratorName = (collaboratorName.replaceAll(' ', '')).substring(0, 20)
        }
        suggestedFilename = collaboratorName + "-" + suggestedFilename
    }

    if (normalize) {
        return normalizeString(suggestedFilename)
    }

    return suggestedFilename
}

/**
 * Replace all special characters in filename with underscore.
 * Allow only letters (with latin letters), numbers, dots and hyph
 * @param {*} filename 
 * @returns 
 */
export const getSuggestedFilename = (filename) => {
    return filename.replace(/[^a-zA-Z0-9.-]/g, '_') 
}

export const openInNewTab = (url) => {
    window.open(url, '_blank').focus();
}

export const dateObject = (date) => {
    if (!date) {
        return null
    }

    if (_isDate(date)) {
        return date
    }

    const result = parseISO(date)
    if (!_isDate(result)) {
        return null
    }

    return result
}

export const normalizeString = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const extractArrayField = (value, displayField) => {
    let result = value || ""
    if (result) {
        result =
            _isArray(result) &&
            _join(
                result.map((element) => element[displayField]),
                ", "
            )
    }

    return result
}

export const getRouteModule = (location) => {
    return location.replace(/\/[^\/]+$/, '')
}

export const getRouteParams = (params, filterConfig, orderBy, order) => {
    if (orderBy) {
        params.orderBy = orderBy
        params.order = order
    }
                
    if (filterConfig) {
        filterConfig
            .filter(field => field.type === constants.DATE_TYPE)
            .forEach(field => {
                const date = _get(params.filter, field.id)
                if (_isDate(date)) {
                    _set(params.filter, field.id, formatDate(date, "yyyy-MM-dd"))
                }
            })
    }
    
    return btoa(JSON.stringify(params))
}

export const cleanUrlParams = (window) => {
    window.history.replaceState(null, "", window.location.pathname)
}

export const processReloadOnSuccessActions = (actions, refresh) => {
    return (actions || []).map((action) => {
        if (_get(action, "reloadOnSuccess", false)) {
            return {
                ...action,
                onExecute: (data, progressHandler, onSuccess, onError) => {
                    return action.onExecute(data, progressHandler, onSuccess, onError).then(refresh)
                }
            }
        }

        return action
    })
}