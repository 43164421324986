import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { createBooleanFormField, createField, createFormField, createFormFieldGroup, createFormFieldset, createSelectFormField, } from "../../../../components/helpers/fieldHelper"
import EntityEditForm from "../../../actions/EntityEditForm"
import _get from "lodash/get"

export const configWithProductsFieldMapping = (t) => {
    return {
        none: t("catalog.product_rates_publications.fields.with_products.none"),
        modified: t("catalog.product_rates_publications.fields.with_products.modified"),
        all: t("catalog.product_rates_publications.fields.with_products.all"),
    }
}

const ProductRatesPublicationForm = ({ selection, onAction, mode = "add" }) => {
    const { t } = useTranslation("vbms")

    const entity = selection || {}

    const formContent = [
        createFormFieldset(null, [
            createFormField(
                createField(
                    "description",
                    t("catalog.product_rates_publications.fields.description"),
                    constants.STRING_TYPE
                ),
                true
            ),
            createFormField(
                createField(
                    "valid_from",
                    t("catalog.product_rates_publications.fields.valid_from"),
                    constants.DATE_TYPE
                ),
                true,
                new Date()
            ),
            createFormFieldGroup([
                createFormField(
                    createField(
                        "date_from",
                        t("catalog.product_rates_publications.fields.date_from"),
                        constants.DATE_TYPE
                    ),
                    true
                ),
                createFormField(
                    createField(
                        "date_to",
                        t("catalog.product_rates_publications.fields.date_to"),
                        constants.DATE_TYPE
                    ),
                    true
                ),
            ]),
            createFormFieldGroup(
                [
                    createSelectFormField(
                        "config.with_products",
                        t("catalog.product_rates_publications.fields.with_products.name"),
                        Object.entries(configWithProductsFieldMapping(t)).map(([key, value]) => ({ value: key, label: value })),
                        () => mode === "add",
                        true,
                        false,
                        "none"
                    ),
                    createBooleanFormField(
                        t,
                        "config.hide_intermediaries",
                        t("catalog.product_rates_publications.fields.hide_intermediaries"),
                        null,
                        false,
                        1
                    ),
                ]
            ),
        ])
    ]

    return (
        <EntityEditForm
            selection={entity}
            formContent={formContent}
            onAction={onAction}
        />
    )
}

export default ProductRatesPublicationForm