import { useTranslation } from "react-i18next";
import { getRecipientsFinishedActions, getResult } from "./NotificationRecipients.functions";
import { createCallbackField, createField, createLanguagesMapping, createLinkField, createSelectFormField, languagesCode2, createAsyncSelectFormField, createFormField } from '../../../../../../components/helpers/fieldHelper';
import constants from "../../../../../../components/entity/constants";
import { Box } from "@mui/material";
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable";
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider";
import _isArray from "lodash/isArray"
import _get from "lodash/get"
import { useState } from "react";
import { useVolcanoAuth } from "../../../../../../context/VolcanoAuthContext";
import { useCache } from "../../../../../../context/cache.context";
import { MailOutlineOutlined, SmsOutlined } from "@mui/icons-material";
import { paymentModesFetcher } from '../../../../../../lib/form-options-fetchers';
import userPermissions from '../../../../../../lib/user-permissions';

const getTableFilter = (apiClient, siteConfig, t, user, cache) => ([
    createField(
        "locator",
        t("notifications.filter.locator"),
        constants.TEXT_TYPE
    ),
    createField(
        "product_name",
        t("notifications.filter.product"),
        constants.TEXT_TYPE
    ),
    createField(
        "name",
        t("notifications.fields.booking.name"),
        constants.TEXT_TYPE
    ),
    createField(
        "email",
        t("notifications.fields.email"),
        constants.TEXT_TYPE
    ),
    createFormField(
        createAsyncSelectFormField(
            "payment_method_id",
            t("bookings.filter.payment_method"),
            () => {
                return paymentModesFetcher(cache, apiClient, t).then((result) => {
                    return result.filter((item) => item.label !== t("bookings.constants.payment_method.paygold") && item.label !== t("bookings.constants.payment_method.oneclick"))
                })
            },
            true,
            () => !user.isIntermediary() && user.hasPermission(userPermissions.PERM_ENTERPRISES_PAYMENT_GATEWAYS_VIEW),
            null,
            false,
            true
        ),
        false,
        []
    ),
    createField(
        "phone",
        t("notifications.fields.phone"),
        constants.TEXT_TYPE
    ),
    createSelectFormField(
        "languages",
        t("notification_templates.fields.recipients.language"),
        Object.entries(createLanguagesMapping(t, languagesCode2(), true))
            .map(([key, value]) => (
                {
                    value: key,
                    label: value
                }
            )),
    ),
])

const NotificationRecipientsBookings = ({ defaultParams }) => {
    const { t } = useTranslation("vbms");
    const { apiClient, siteConfig } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()

    const entityId = useState(defaultParams.notification_id)

    const actions = getRecipientsFinishedActions(entityId, 'booking', t)

    const columns = [
        createLinkField(
            createField(
                "bar_code",
                t("notifications.fields.booking.locator"),
                constants.TEXT_TYPE
            ),
            {
                route: "bookings",
                field: "recipient_data_id",
            }
        ),
        createField(
            "product",
            t("bookings.fields.product"),
            constants.STRING_TYPE
        ),
        createField(
            "experience",
            t("bookings.fields.experience"),
            constants.STRING_TYPE
        ),
        createField(
            "name",
            t("notifications.fields.booking.name"),
            constants.STRING_TYPE,
        ),
        createField(
            "full_reservation_date",
            t("bookings.fields.booking_date"),
            constants.STRING_TYPE
        ),
        createField(
            "email",
            t("notifications.fields.email"),
            constants.STRING_TYPE,
        ),
        createField(
            "phone",
            t("notifications.fields.phone"),
            constants.STRING_TYPE,
        ),
        createField(
            "language",
            t("notifications.fields.language"),
            constants.STRING_TYPE,
            createLanguagesMapping(t),
        ),
        createCallbackField(
            "logs",
            <MailOutlineOutlined />,
            constants.STRING_TYPE,
            (value) => {
                if (value && value["email"]) {
                    return (
                        <Box>{getResult(value["email"], t)}</Box>
                    )
                } else {
                    return
                }
            },
        ),
        createCallbackField(
            "logs",
            <SmsOutlined />,
            constants.STRING_TYPE,
            (value) => {
                if (value && value["sms"]) {
                    return (
                        <Box>{getResult(value["sms"], t)}</Box>
                    )
                } else {
                    return
                }
            },
        ),
    ]

    const filterConfig = getTableFilter(apiClient, siteConfig, t, volcanoAuth.user, cache)

    return (
        <CollectionDataTable
            ignoreSearchParams={true}
            fetcher={(query) => {

                let productsFilter = [];
                if (_get(query, "product_id", null)) {
                    if (_isArray(_get(query, "product_id", null))) {
                        _get(query, "product_id", null).forEach(prd => {
                            productsFilter.push(prd.id)
                        });
                    } else {
                        productsFilter.push(_get(query, "product_id", null));
                    }
                }

                const params = {
                    ...defaultParams
                    , ...query,
                    limit: 1000,
                    product_id: productsFilter
                }

                return apiClient.notifications.getRecipients(defaultParams.notification_id, params)
            }}
            columns={columns}
            actions={actions}
            filterConfig={filterConfig}
        />
    )

}

export default NotificationRecipientsBookings