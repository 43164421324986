import _isArray from "lodash/isArray"
import _join from "lodash/join"
import _isObject from "lodash/isObject"
import EntityField from "./EntityField"
import { Fragment } from "react"

const ListFieldValue = ({ value, displayField }) => {
    if (!_isArray(value)) {
        return null
    }

    if (_isObject(displayField)) {
        // display field is an entity field definition
        return (
            <>
                {value.map((element, key) => {
                    if (!_isObject(element)) {
                        return element
                    }

                    return (
                        <Fragment key={key}>
                            {key > 0 && ", "}
                            <EntityField field={displayField} entity={element} />
                        </Fragment>
                    )
                })}
            </>
        )
    }

    let result = value || ""
    result =
        _isArray(result) &&
        _join(
            result.map((element) => (displayField ? element[displayField] : element)),
            ", "
        )

    return <>{result}</>
}

export default ListFieldValue
