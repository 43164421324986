import { useTranslation } from "react-i18next"
import constants from "../../components/entity/constants"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { createAsyncSelectFormField, createCallbackField, createField, createFormFieldGroup, createFormFieldset, createPasswordFormField, createFormFieldOption, createDialogFormField, createDependentFormField } from '../../components/helpers/fieldHelper'
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import { useVolcanoAuth } from "../../context/VolcanoAuthContext"
import { rolesFetcher, buildOptions, crmSchemaKeyItemsFetcher } from "../../lib/form-options-fetchers"
import { useCache } from "../../context/cache.context"
import useSnackBars from "../../components/Snackbar/snack-bar.context"
import userPermissions from "../../lib/user-permissions"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

const UserFormPage = (props) => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const { apiClient } = useVolcanoApiClient()
    const volcanoAuth = useVolcanoAuth()
    const cache = useCache()
    const navigate = useNavigate()
    const { addAlert } = useSnackBars()

    const fetcher = () => {
        if (!entityId) {
            return new Promise((res) => res({
                hasAction: (action) => false
            }))
        }

        return apiClient.user.getApplicationUser(entityId).then((user) => {
            user.roles = user.roles.map((role) => role.id.toString())

            // formatting intermediary for dialog form field
            if (!_isEmpty(user.crm_intermediary)) {
                user.crm_intermediary = [{
                    id: user.crm_intermediary.id,
                    label: user.crm_intermediary.name,
                    value: user.crm_intermediary
                }];
            }

            // delete password property from user
            delete user.password

            return user
        })
    }

    const onSaveHandler = (user) => {
        // clone user
        const userData = {
            ...user,
        }

        // process user roles
        if (user.roles) {
            userData.roles = user.roles.map((role) => ({ id: parseInt(role) }))
        }

        if (!_isEmpty(user.crm_intermediary)) {
            userData.crm_intermediary = userData.crm_intermediary[0]
        } else {
            userData.crm_intermediary = { id: "" }
        }

        if (!entityId) {
            apiClient.user.createUser(userData, 'application')
                .then((user) => {
                    navigate('/settings/users')
                })
                .catch((error) => {
                    addAlert("Error: " + error.message)
                })
        } else {
            apiClient.user.editUser(entityId, userData, 'application')
                .then((user) => {
                    navigate('/settings/users/' + entityId)
                })
                .catch((error) => {
                    addAlert("Error: " + error.message)
                })
        }
    }

    const formContent = [
        createFormFieldset(t("settings_system_users.form.fieldset_account"), [
            createField(
                "username",
                t("settings_system_users.fields.username"),
                constants.STRING_TYPE
            ),
            createPasswordFormField(
                "password",
                t("settings_system_users.fields.password"),
                "password_repeat",
                t("settings_system_users.fields.password_repeat"),
                false,
                (user) => !user.hasOwnProperty('id')
            ),
            createField(
                "active",
                t("settings_system_users.fields.active"),
                constants.BOOLEAN_TYPE,
                null,
                (user) => user.hasAction("change_state")
            ),
            {
                ...createAsyncSelectFormField(
                    "roles",
                    t("settings_system_users.fields.role"),
                    () => rolesFetcher(cache, apiClient, t, null, false),
                    true,
                    (user) => user.hasAction("change_roles"),
                    (value) => value,
                ), viewMode: "chip",
            },
            createDialogFormField(
                "crm_intermediary",
                t("bookings.filter.intermediary"),
                false,
                {
                    filterConfig: [
                        createField(
                            "name",
                            t("intermediaries.fields.name"),
                            constants.STRING_TYPE
                        ),
                        createField(
                            "vat_number",
                            t("common.fields.vat_type.nif"),
                            constants.STRING_TYPE
                        ),
                        createAsyncSelectFormField(
                            "state",
                            t("intermediaries.fields.state"),
                            () => crmSchemaKeyItemsFetcher("state", cache, apiClient, t, true),
                            false
                        ),
                    ],
                    columns: [
                        createField(
                            "name",
                            t("intermediaries.fields.name"),
                            constants.STRING_TYPE
                        )
                    ],
                    fetcher: (params) => apiClient.intermediary.getIntermediaries(params)
                },
                (value) => (
                    {
                        id: value.id,
                        label: value.name,
                        entity: value
                    }
                ),
                () => !volcanoAuth.user.isIntermediary() &&
                    (
                        volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_VIEW) ||
                        volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_VIEW_OWN)
                    )
            ),
            createDependentFormField(
                "crm_intermediary_office.id",
                t("bookings.filter.office"),
                constants.SELECT_TYPE,
                (values) => {
                    let options = [createFormFieldOption("", t("intermediaries.offices.constants.no_office"))]
                    const intermediaryId = _get(values, "crm_intermediary[0].id")

                    if (intermediaryId) {
                        return apiClient.intermediary.getIntermediaryOffices(intermediaryId)
                            .then((offices) => options.concat(buildOptions(offices.getItems(), t, "id", "name", false)))
                    }

                    return options
                },
                () => !volcanoAuth.user.isIntermediary() &&
                    (
                        volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_VIEW) ||
                        volcanoAuth.user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_VIEW_OWN)
                    )
            ),
            createAsyncSelectFormField(
                "crm_intermediary_office.id",
                t("bookings.filter.office"),
                () => apiClient.intermediary.getIntermediaryOffices(volcanoAuth.user.crm_intermediary.id)
                    .then((offices) => [createFormFieldOption("", t("intermediaries.offices.constants.no_office"))]
                        .concat(buildOptions(offices.getItems(), t, "id", "name", false))),
                false,
                () => volcanoAuth.user.isIntermediary()
            ),
        ]),
        createFormFieldset(t("settings_system_users.form.fieldset_contact"), [
            createFormFieldGroup([
                createField(
                    "first_name",
                    t("settings_system_users.fields.name"),
                    constants.STRING_TYPE
                ),
                createField(
                    "last_name",
                    t("settings_system_users.fields.last_name"),
                    constants.STRING_TYPE
                ),
            ]),
            createFormFieldGroup([
                createField(
                    "email",
                    t("settings_system_users.fields.email"),
                    constants.STRING_TYPE
                ),
                createField(
                    "phone",
                    t("settings_system_users.fields.phone"),
                    constants.PHONE_TYPE
                ),
            ]),
            createField(
                "salesman.id_card",
                t("settings_system_users.fields.id_card"),
                constants.STRING_TYPE,
                null,
                (user) => user.hasOwnProperty('salesman') || volcanoAuth.user.isIntermediary()
            )
        ]),
    ]

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "username",
                t("settings_system_users.form.edit_title"),
                constants.STRING_TYPE,
                (value, entity) => {
                    if (entity.id) {
                        return t("settings_system_users.form.edit_title", {
                            username: value,
                        });
                    }
                    return t("settings_system_users.form.add_title");
                }
            )}
            onSave={onSaveHandler}
            formContent={formContent}
        />
    )
}

export default UserFormPage
