import { useTranslation } from "react-i18next"
import {
    createArrayField,
    createCallbackField,
    createCommonStateMapping,
    createCommonVisibilityMapping,
    createField,
    createLinkField
} from "../../../../../../../components/helpers/fieldHelper"
import constants from "../../../../../../../components/entity/constants"
import ArrayDataTable from "../../../../../../../components/Datatable/ArrayDataTable"
import _has from "lodash/has"

const ExperienceProducts = ({ entity }) => {
    const { t } = useTranslation("vbms")

    const columns = [
        createField(
            "id",
            t("catalog.experiences.fields.id"),
            constants.STRING_TYPE
        ),
        createLinkField(
            createField(
                "name",
                t("catalog.products.fields.name"),
                constants.TEXT_TYPE
            ),
            {
                route: "catalog_products",
                field: "id",
            }
        ),
        createCallbackField(
            "experience",
            t("catalog.experiences.fields.main_experience"),
            constants.STRING_TYPE,
            (value) => !value ? t('common.yes') : t('common.no')
        ),
        createField(
            "state",
            t("common.states.active"),
            constants.STRING_TYPE,
            createCommonStateMapping(t)
        ),
        createField(
            "visibility",
            t("common.visibility.title"),
            constants.STRING_TYPE,
            createCommonVisibilityMapping(t)
        ),
    ]

    if (!entity) {
        return null
    }

    return (
        <ArrayDataTable
            data={entity.products}
            columns={columns}
            highlight={(product) => product.visibility === "private" ? "background.warning.main" : ""}
        />
    )
}

export default ExperienceProducts
