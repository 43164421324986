import { createTabBarItem } from '../../../components/helpers/fieldHelper';
import _isEmpty from "lodash/isEmpty"
import _has from "lodash/has"
import { Delete } from '@mui/icons-material';
import NotificationProducts from '../../../components/Volcano/Notifications/NotificationProducts';
import availableRoutes from '../../../routes/availableRoutes';
import NotificationRecipientsGuides from './components/tabs/NotificationRecipients/NotificationRecipientsGuides';
import NotificationRecipientsDetails from './components/tabs/NotificationRecipients/NotificationRecipientsDetails';
import NotificationRecipientsBookings from './components/tabs/NotificationRecipients/NotificationRecipientsBookings';
import NotificationRecipientsPrepareBookings from './components/tabs/NotificationRecipients/NotificationRecipientsPrepareBookings';
import NotificationRecipientsPrepareGuides from './components/tabs/NotificationRecipients/NotificationRecipientsPrepareGuides';
import { Box } from '@mui/material';
import NotificationSessions from './components/tabs/NotificationSessions';
import NotificationRecipientsPrepareIntermediaries from './components/tabs/NotificationRecipients/NotificationRecipientsPrepareIntermediaries';
import NotificationRecipientsIntermediaries from './components/tabs/NotificationRecipients/NotificationRecipientsIntermediaries';
import NotificationRecipientsFailed from './components/tabs/NotificationRecipients/NotificationRecipientsFailed';

const getActions = (entity, t, apiClient, volcanoAuth, navigate, edit) => {
    let actions = [
        {
            id: "delete",
            title: t("common.delete"),
            scope: "row",
            icon: Delete,
            showLoading: true,
            onExecute: (product, progressHandler, onSuccess, onError) => {
                return apiClient.notifications.deleteProductFromNotification(entity.id, product.id)
                    .then((result) => {
                        onSuccess(t("notifications.actions.delete_product.confirm"))
                        navigate(availableRoutes.notifications_view.path.replace(":entityId", entity.id))
                    })
                    .catch((error) => {
                        onError(t("notifications.actions.delete_product.error"))
                        throw error
                    })
            },
            condition: (entity) => edit
        },
    ]
    return actions
}

export const getTabNavigationItems = (t, apiClient, volcanoAuth, navigate) => {

    return ([
        createTabBarItem(
            t("notifications.view.navbar.products"),
            ({ entity }) => {
                return (
                    <NotificationProducts
                        products={entity.products}
                        actions={getActions(entity, t, apiClient, volcanoAuth, navigate, _has(entity.getActions(), "edit"))}
                    />
                )
            }
            ,
            null,
            (entity) => (!_isEmpty(entity.products))
        ),
        createTabBarItem(
            t("notifications.view.navbar.recipients"),
            ({ entity }) => {
                return (
                    <NotificationRecipientsDetails
                        entityId={entity.id}
                        recipients={entity.details}
                        edit={_has(entity.getActions(), "edit")}
                    />
                )
            },
            null,
            (entity) => (!_isEmpty(entity.details))
        ),
        createTabBarItem(
            t("notifications.view.navbar.sessions"),
            ({ entity }) => {
                return (
                    <NotificationSessions
                        entityId={entity.id}
                        products={entity.products}
                        date={entity.date}
                        apiClient={apiClient}
                    />
                )
            },
            null,
            (entity) => (entity.date)
        ),
        createTabBarItem(
            t("notifications.view.navbar.configure_bookings"),
            ({ entity }) => {
                return (
                    <NotificationRecipientsPrepareBookings
                        defaultParams={{
                            notification_id: entity.id,
                            recipient_type: 'booking'
                        }}
                        details={entity.details}
                    />
                )
            },
            null,
            (entity) => (entity.state === 'ready' && !_isEmpty(entity.recipients.booking))
        ),
        createTabBarItem(
            t("notifications.view.navbar.configure_intermediaries"),
            ({ entity }) => {
                return (
                    <NotificationRecipientsPrepareIntermediaries
                        defaultParams={{
                            notification_id: entity.id,
                            recipient_type: 'collaborator'
                        }}
                        details={entity.details}
                    />
                )
            },
            null,
            (entity) => (entity.state === 'ready' && !_isEmpty(entity.recipients.collaborator))
        ),
        createTabBarItem(
            t("notifications.view.navbar.configure_guides"),
            ({ entity }) => {
                return (
                    <NotificationRecipientsPrepareGuides
                        defaultParams={{
                            notification_id: entity.id,
                            recipient_type: 'guide'
                        }}
                        details={entity.details}
                    />
                )
            },
            null,
            (entity) => (entity.state === 'ready' && !_isEmpty(entity.recipients.guide))
        ),
        createTabBarItem(
            t("notifications.view.navbar.bookings"),
            ({ entity }) => {
                return (
                    <NotificationRecipientsBookings
                        defaultParams={{
                            notification_id: entity.id,
                            recipient_type: 'booking'
                        }}
                    />
                )
            },
            null,
            (entity) => ((entity.state === 'pending' ||
                entity.state === 'cancelled' ||
                entity.state === 'finished')
                && !_isEmpty(entity.recipients.booking))
        ),
        createTabBarItem(
            t("notifications.view.navbar.intermediaries"),
            ({ entity }) => {
                return (
                    <NotificationRecipientsIntermediaries
                        defaultParams={{
                            notification_id: entity.id,
                            recipient_type: 'collaborator'
                        }}
                    />
                )

            },
            null,
            (entity) => ((entity.state === 'pending' ||
                entity.state === 'cancelled' ||
                entity.state === 'finished')
                && !_isEmpty(entity.recipients.collaborator))
        ),
        createTabBarItem(
            t("notifications.view.navbar.guides"),
            ({ entity }) => {
                return (
                    <NotificationRecipientsGuides
                        defaultParams={{
                            notification_id: entity.id,
                            recipient_type: 'guide'
                        }}
                    />
                )
            },
            null,
            (entity) => ((entity.state === 'pending' ||
                entity.state === 'cancelled' ||
                entity.state === 'finished')
                && !_isEmpty(entity.recipients.guide))
        ),
        createTabBarItem(
            t("notifications.view.navbar.failed"),
            ({ entity }) => {
                return (
                    <NotificationRecipientsFailed
                        entityId={entity.id}
                        elements={entity.failed}
                    />
                )
            },
            null,
            (entity) => ((entity.state === 'pending' ||
                entity.state === 'cancelled' ||
                entity.state === 'finished')
                && !_isEmpty(entity.recipients.failed))
        ),
        createTabBarItem(
            t("notifications.view.navbar.recipients"),
            ({ entity }) => {
                return (
                    <Box>{t("notifications.view.no_recipients")}</Box>
                )
            }
            ,
            null,
            (entity) => ((entity.state === 'ready' ||
                entity.state === 'in_edition') &&
                _isEmpty(entity.details) &&
                _isEmpty(entity.recipients) &&
                _isEmpty(entity.products))
        ),
    ])
};