import {getActions as getRowActions} from "../../../Content/Tags/TagPage/TagPage.actions";
import userPermissions from "../../../../lib/user-permissions";
import ActivityConfigForm from "../ActivityConfigForm";


export const getActions = (apiClient, t, navigate, activityConfig) => [
    {
        id: "add_activity_config",
        title: t("common.create"),
        withDialog: {
            title: t("activity_configs.actions.add.modal_title"),
            content: ActivityConfigForm
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.activityManager.activityConfig.addActivityConfig(data)
                .then((result) => {
                    onSuccess(
                        t("activity_configs.actions.add.confirm", {
                            name: result.name,
                        })
                    )
                })
                .catch((error) => {
                    onError(
                        t("activity_configs.actions.add.error", {
                            name: data.name,
                        })
                    )
                })
        },
        condition: () => activityConfig.hasAnyPermission([
            userPermissions.PERM_ACTIVITY_CONFIGS_ADMINISTER,
            userPermissions.PERM_ACTIVITY_CONFIGS_ADMINISTER_OWN
        ])
    }
]


export const getTableActions = (...props) => getRowActions(...props)
