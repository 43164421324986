import { useTranslation } from "react-i18next"
import { createAsyncSelectFormField, createFormFieldset } from "../../../../components/helpers/fieldHelper"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import { buildOptions } from "../../../../lib/form-options-fetchers"
import EntityEditForm from "../../../actions/EntityEditForm"

const AddProductContainerForm = ({ selection, onAction }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()

    const entity = selection || {}

    const formContent = [
        createFormFieldset(null, [
            createAsyncSelectFormField(
                "product_rates_publication.id",
                t("catalog.product_rates_publications.title"),
                () => {
                    const params = {
                        "not_product_id": entity.id,
                        "not_container_id": entity.container.id,
                    }
                    return apiClient.catalog.productRatesPublication.getProductRatesPublications(params)
                        .then((items) => buildOptions(items.getItems(), t, "id", "description", false))
                },
                false
            ),            
        ])
    ]

    return (
        <EntityEditForm
            selection={entity}
            formContent={formContent}
            onAction={onAction}
        />
    )
}

export default AddProductContainerForm