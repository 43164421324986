import {getActions as getRowActions} from "../../../Content/Tags/TagPage/TagPage.actions"
import userPermissions from "../../../../lib/user-permissions";
import LodgingForm from "../LodgingForm";


export const getActions = (apiClient, t, navigate, lodging) => [
    {
        id: "add_lodging",
        title: t("common.create"),
        withDialog: {
            title: t("lodgings.actions.add.modal_title"),
            content: LodgingForm
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.activityManager.lodging.addLodging(data)
                .then((result) => {
                    onSuccess(
                        t("lodgings.actions.add.confirm", {
                            name: result.name,
                        })
                    )
                })
                .catch(() => {
                    onError(
                        t("lodgings.actions.add.error", {
                            name: data.name,
                        })
                    )
                })
        },
        condition: () => lodging.hasAnyPermission([
            userPermissions.PERM_PICKUP_POINTS_ADMINISTER,
            userPermissions.PERM_PICKUP_POINTS_ADMINISTER_OWN
        ])
    }
]


export const getTableActions = (...props) => getRowActions(...props)
