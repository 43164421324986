import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { formatDecimal } from "../../../lib/utils"
import Title from "../../../components/Display/Title"
import { WarningOutlined } from "@mui/icons-material"
import BaseActionsGroup from "../../../components/action/BaseActionsGroup"
import ChipList from "../../../components/Display/ChipList"
import { processPaxRestrictionValue } from "./product-rates-utils"
import _isFunction from "lodash/isFunction"

const BACKGROUND_COLORS = ["#f5f5f5", "#ffffff"]

export const CUSTOMER_TYPE_IDS = {
    ADULTS: 16,
    CHILDREN: 14,
    RESIDENTS: 18,
    NOT_RESIDENTS: 19,
    GUIDES: 10
}

const processActions = (actions, refresh) => {
    return (actions || []).map((action) => {
        if (action?.reloadOnSuccess) {
            return {
                ...action,
                onExecute: (data, progressHandler, onSuccess, onError) => {
                    const reloadFetcher = _isFunction(action.reloadFetcher) ? action.reloadFetcher : refresh
                    if (reloadFetcher) {
                        return action.onExecute(data, progressHandler, onSuccess, onError).then(() => reloadFetcher())
                    }

                    return action.onExecute(data, progressHandler, onSuccess, onError)
                }
            }
        }

        return action
    })
}

const ContentTableCell = ({ align, backgroundColor, colSpan, width, whiteSpace, children }) => (
    <TableCell
        size="small"
        align={align || "left"}
        colSpan={colSpan || 1}
        width={width || "auto"}
        sx={{
            fontSize: "0.775rem",
            backgroundColor: backgroundColor || BACKGROUND_COLORS[1]
        }}
    >
        {children}
    </TableCell>
)

const EmptyTableCell = (props) => <ContentTableCell {...props} />

const ProductRatesTableHead = ({ product }) => {
    const { t } = useTranslation("vbms")

    const backgroundColor = BACKGROUND_COLORS[0]

    const qtyRestrictions = product.getQtyRestrictions()

    const withNetPrice = product.container.container_type !== 'enterprise'

    return (
        <TableHead>
            <TableRow>
                <EmptyTableCell colSpan={withNetPrice ? 2 : 1} width={"25%"} />
                <Fragment key={"rate-group.id"}>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.config.min_pax")}</ContentTableCell>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.config.max_pax")}</ContentTableCell>
                    {!withNetPrice && (
                        <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.retail_price")}</ContentTableCell>
                    )}
                    {withNetPrice && (
                        <>
                            <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.net_price")}</ContentTableCell>
                            <ContentTableCell align="right" backgroundColor={backgroundColor}>%</ContentTableCell>
                        </>
                    )}
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{t("catalog.rates_table.rates.active") + " / " + t("common.visibility.title")}</ContentTableCell>
                </Fragment>
            </TableRow>
            <TableRow>
                <ContentTableCell>{t("catalog.rates_table.rates.customer_type")}</ContentTableCell>
                {withNetPrice && <ContentTableCell align="right">{t("catalog.rates_table.rates.retail_price")}</ContentTableCell>}
                <Fragment key={"rate.group.id"}>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{processPaxRestrictionValue(qtyRestrictions.min)}</ContentTableCell>
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{processPaxRestrictionValue(qtyRestrictions.max)}</ContentTableCell>
                    <EmptyTableCell backgroundColor={backgroundColor} colSpan={withNetPrice ? 2 : 1} />
                    <ContentTableCell align="right" backgroundColor={backgroundColor}>{(product.active ? t("common.yes") : t("common.no")) + " / " + t("common.visibility." + product.visibility)}</ContentTableCell>
                </Fragment>
            </TableRow>
        </TableHead>
    )
}

const ProductRatesTableBodyRow = ({ rate, rateGroups, withNetPrice }) => {
    const { t, i18n } = useTranslation("vbms")

    return (
        <TableRow
            key={rate.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">{rate.customer_types.name}</TableCell>
            {withNetPrice && <TableCell align="right">{rate.retail_price.formatted_currency}</TableCell>}
            {rateGroups.map((rateGroup, index) => {
                const backgroundColor = index % 2 === 0 ? "#f5f5f5" : "#ffffff"
                const rateGroupRate = rateGroup.rates.find((rateGroupRate) => rateGroupRate.customer_types.ids === rate.customer_types.ids)

                const active = rateGroupRate.active

                const data = {
                    price: withNetPrice ? rateGroupRate.net_price.formatted_currency : rateGroupRate.retail_price.formatted_currency,
                    percentage: formatDecimal(i18n, parseFloat(rateGroupRate.commission_percentage) * 100),
                    active: active,
                    qty_restrictions: {
                        min: rateGroupRate.qty_restrictions?.min || 0,
                        max: rateGroupRate.qty_restrictions?.max || 0,
                    },
                }

                return (
                    <Fragment key={rateGroup.id}>
                        <ContentTableCell align="right" backgroundColor={backgroundColor}>{processPaxRestrictionValue(data.qty_restrictions.min)}</ContentTableCell>
                        <ContentTableCell align="right" backgroundColor={backgroundColor}>{processPaxRestrictionValue(data.qty_restrictions.max)}</ContentTableCell>
                        <TableCell align="right" sx={{ backgroundColor: backgroundColor }}>{data.price}</TableCell>
                        {withNetPrice && <ContentTableCell align="right" backgroundColor={backgroundColor}>{data.percentage}</ContentTableCell>}
                        <ContentTableCell align="right" backgroundColor={backgroundColor}>{data.active ? t("common.yes") : t("common.no")}</ContentTableCell>
                    </Fragment>
                )
            })}
        </TableRow>
    )
}

const ProductRatesTableBody = ({ productRates, rateGroups, withNetPrice }) => {
    rateGroups = rateGroups.map((rateGroup) => ({ ...rateGroup, rates: productRates }))

    return (
        <TableBody>
            {productRates.map((rate) => <ProductRatesTableBodyRow key={rate.id} rate={rate} rateGroups={rateGroups} withNetPrice={withNetPrice} />)}
        </TableBody>
    )
}

const ProductConfigurationFlags = ({ product }) => {
    const { t } = useTranslation("vbms")

    if (!product.container_configuration) {
        return null
    }

    let tags = []

    // show in pdf
    if (product.container_configuration.show_in_pdf) {
        tags.push({
            id: "show_in_pdf",
            label: t("catalog.product_containers.fields.config.show_in_pdf")
        })
    }

    // required rates
    if (product.container_configuration.customer_types_restrictions && 
        product.container_configuration.customer_types_restrictions.includes(CUSTOMER_TYPE_IDS.ADULTS)) {
        tags.push({
            id: "rates_restrictions",
            label: t("catalog.product_containers.fields.config.customer_type_required_adult")
        })
    }

    return (
        <Box mb={0.5}>
            <ChipList selection={tags} containerProps={{ justifyContent: "flex-end" }} chipProps={{ size: "small" }} />
        </Box>
    )
}

const ProductRatesTable = ({ product, hideTitle, actions }) => {
    if (!product.rates) {
        return null
    }

    const rateGroups = [{ id: 'test' }]

    return (
        <Box sx={{ width: "100%" }}>
            <Stack direction="row" justifyContent="space-between">
                <Box
                    display="flex"
                    sx={{ "& > div": { fontWeight: 500 } }}
                >
                    {product.custom_configuration && (<WarningOutlined color="warning" sx={{ marginRight: 1 }} />)}
                    {!hideTitle && <Title level={"h5"}>{product.name}</Title>}
                </Box>
                <Box mt={-0.5} display="flex" alignItems="center">
                    <ProductConfigurationFlags product={product} />
                    <BaseActionsGroup
                        selection={product}
                        variant="outlined"
                        actions={processActions(actions)}
                        actionsNumberDesktop={2}
                    />
                </Box>
            </Stack>
            <Box>
                <Paper elevation={0}>
                    <TableContainer sx={{ maxWidth: "100%" }}>
                        <Table size="small">
                            <ProductRatesTableHead product={product} />
                            <ProductRatesTableBody
                                productRates={product.rates}
                                rateGroups={rateGroups}
                                withNetPrice={product.container.container_type !== "enterprise"}
                            />
                        </Table>
                    </TableContainer>                    
                </Paper>
            </Box>
        </Box>
    )
}

export default ProductRatesTable